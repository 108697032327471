.StaffList {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.StaffList-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.StaffList-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
  /* background-color: #FFFAEE; */
}
.StaffList-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.StaffList-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.StaffList-space-body {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.StaffList-title-board {
  width: 85%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.StaffList-title-board h2 {
  color: #1f1515;
  font-size: 1.4vw;
}
.StaffList-title-board input {
  border: none;
  background: transparent;
  outline: none;
  color: #1f1515;
  border-bottom: 0.2vh solid #1f1515;
  font-size: 1.4vw;
}

.StaffList-board {
  width: 85%;
  height: 70vh;
  background-color: #FFFAEE;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.StaffList-center-board {
  width: 90%;
  height: 65vh;
}
.StaffList-center-board p {
  color: #1f1515;
  font-size: 1.2vw;
}
.StaffList-table-head {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.StaffList-table-head p {
  width: 20%;
  font-weight: bold;
}
.StaffList-table-body {
  width: 100%;
  height: 50vh;
  overflow-y: scroll;
}
.StaffList-table-body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* For Firefox */
}
/* .StaffList-table-body::-webkit-scrollbar:hover {
  width: 8px; 
  background: rgba(0, 0, 0, 0.1); 
} */
.StaffList-table-row {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.StaffList-table-row p {
  width: 20%;
}

.StaffList-mobile-header {
  display: none;
}


@media (max-width: 650px) {
  .StaffList-mobile-header {
    display: block;
  }
  .StaffList-space {
    background-color: #FDF0D5;
  }
  .StaffList-profile {
    display: none;
  }
  .StaffList-space  {
    width: 100%;
  }
  .StaffList-space-head {
    display: none;
  }
  .StaffList-table-head {
    display: none;
  }
  .StaffList-board {
    width: 100%;
    box-shadow: none;
    background-color: #FDF0D5;
    height: auto;

  }
  .StaffList-table-body {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    height: 70vh;
  }
  .StaffList-title-board {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    gap: 2vh;
    padding: 1vh 0;
  }
  .StaffList-title-board h2 {
    font-size: 5vw;
    font-weight: normal;
  }
  .StaffList-title-board input {
    font-size: 4vw;
  }
  .StaffList-center-board {
    height: auto;
  }
}