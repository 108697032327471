.CashierDashboard {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.CashierDashboard-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.CashierDashboard-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
}
.CashierDashboard-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.CashierDashboard-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.CashierDashboard-space-body {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  border-bottom: 0.3vh solid #1f1515;
}
.CashierDashboard-board-up {
  width: 100%;
  height: 35vh;
}
.CashierDashboard-board-up p {
  color: #1f1515;
  font-size: 1.8vw;
  margin: 2vh 0;
  margin-left: 5vw;
}
.CashierDashboard-board-down {
  width: 100%;
  height: 35vh;
  display: flex;
  align-items: center;
}
.CashierDashboard-board-down-container,
.CashierDashboard-board-up-container {
  margin-left: 5vw;
  height: 27vh;
  width: 90%;
}
.CashierDashboard-board-up-container button {
  font-size: 1.3vw;
  padding: 1vh 2vw;
  color: #fdf0d5;
  border: none;
  background-color: #1f1515;
  border-radius: 0.5vw;
  margin-right: 1.5vw;
  margin-top: 1vh;
}
.CashierDashboard-board-down-container button {
  font-size: 1.3vw;
  padding: 2vh 2vw;
  color: #fdf0d5;
  border: none;
  background-color: #1f1515;
  border-radius: 1vw;
  margin-right: 1.5vw;
  margin-top: 1vh;
}

.CashierDashboard-mobile-header {
  display: none;
}

@media (max-width: 650px) {
  .CashierDashboard-mobile-header {
    display: block;
  }
  .CashierDashboard-profile {
    display: none;
  }
  .CashierDashboard-space {
    width: 100%;
  }
  .CashierDashboard-space-head {
    display: none;
  }
  .CashierDashboard-board-down-container,
  .CashierDashboard-board-up-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .CashierDashboard-board-up p {
    font-size: 5vw;
  }
  .CashierDashboard-board-up-container button {
    font-size: 4vw;
    padding: 1vh 2vw;
  }

  .CashierDashboard-board-down-container button {
    font-size: 4vw;
    /* padding: 2vh 2vw; */
  }
}

/* @media (max-width: 400px) {
  
} */