.StaffInformation {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}
.modal-buttons {
  margin-top: 20px;
}
.modal-buttons button {
  background-color: #1f1515;
  color: #fdf0d5;
  margin: 0 10px;
  border-radius: 0.5vw;
}
.modal-content h3 {
  color: #1f1515; 
}
.modal-content input {
  color: #1f1515; 
  border: 1px solid #ccc; /* Light gray border */
}
.StaffInformation-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.StaffInformation-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error-message {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.StaffInformation-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.StaffInformation-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.StaffInformation-space-body {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.StaffInformation-board {
  width: 85%;
  height: 70vh;
  background-color: rgb(255, 246, 238);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.StaffInformation-center-board {
  width: 90%;
  height: 60vh;
  /* border: thin solid #000; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.StaffInformation-center-cont {
  width: 50%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.StaffInformation-center-cont p,
.StaffInformation-center-cont b {
  color: #1f1515;
  font-size: 1.3vw;
}
.StaffInformation-center-cont h2 {
  color: #1f1515;
  font-size: 1.95vw;
}
.StaffInformation-center-cont .StaffInformation-cont-btn button {
  border: none;
  background-color: #1f1515;
  color: #fdf0d5;
  padding: 1vh 1.5vw;
  border-radius: 0.7vw;
  margin-right: 2vw;
  font-size: 1vw;
}
.StaffInformation-information p {
  margin: 1.5vh 0;
}
.ContRight {
  height: 50vh;
}
.StaffInformation-contRight-up {
  display: flex;
}
.StaffInformation-contRight-up {
  /* width: 140px; */
  display: flex;
  justify-content: space-between;
  height: 20vh;
}
.StaffInformation-contRight-up img {
  width: 9vw;
  height: 8vw;
  border-radius: 2vw;
  align-items: flex-end;
}
.StaffInformation-contRight-down {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.StaffInformation-contRight-down img {
  width: 19vw;
  height: auto;
  border-radius: 3vw;
}
.StaffInformation-mobile-salary {
  display: none;
}
.StaffInformation-mobile-nimc {
  display: none;
}

.StaffInformation-mobile-header {
  display: none;
}

@media (max-width: 650px) {
  .StaffInformation-mobile-header {
    display: block;
  }
  .StaffInformation-profile {
    display: none;
  }
  .StaffInformation-space {
    width: 100%;
    height: auto;
  }
  .StaffInformation-center-board {
    flex-direction: column-reverse;
    height: auto;
  }
  .StaffInformation-board {
    height: auto;
    box-shadow: none;
  }
  .StaffInformation-space-body {
    height: auto;
  }
  .StaffInformation-space-head {
    /* height: auto; */
    display: none;
  }
  .StaffInformation {
    height: auto;
  }
  .StaffInformation-center-cont {
    width: 100%;
    height: auto;
    gap: 5vh;
  }
  .StaffInformation-contRight-down {
    justify-content: center;
    display: none;
  }
  .StaffInformation-contRight-down img {
    width: 70%;
  }
  .StaffInformation-contRight-up {
    align-items: center;
    justify-content: center;
    height: auto;
    margin-top: 5vh;
  }
  .StaffInformation-contRight-up img {
    width: 40%;
    height: auto;
    border-radius: 60%;
  }
  .StaffInformation-contRight-up div {
    display: none;
  }
  .StaffInformation-information p {
    font-size: 3.5vw;
  }
  .StaffInformation-center-cont p b,
  .StaffInformation-center-cont p {
    font-size: 3.5vw;
  }
  .StaffInformation-center-cont h2 {
    font-size: 4vw;
  }
  .StaffInformation-center-cont .StaffInformation-cont-btn button {
    font-size: 3vw;
  }
  .StaffInformation-mobile-salary {
    display: block;
    margin-bottom: 5vh;
  }
  .StaffInformation-mobile-nimc {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .StaffInformation-mobile-nimc img {
    width: 90%;
    height: auto;
    border-radius: 2vw;
  }
  
}
