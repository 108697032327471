.dashboard-header {
    width: 100%;
    height: 10vh;
    background-color: rgba(31, 21, 21, 0.9);
}
.dbr-nav {
    width: inherit;
    height: 10vh;
    border-bottom: thin solid #fdf0d5;
    display: flex;
    align-items: center;
}
.dbr-logo-holder {
    width: 30%;
    display: flex;
}
.dbr-logo-holder img {
    width: 12vw;
    margin-left: 10px;
}
.dbr-nav-list {
    width: 70%;
    display: flex;
}
.dbr-nav-list ul {
    display: flex;
    list-style: none;
    margin-left: 2vw
}
.dbr-nav-list ul li {
    display: inline-block;
    margin: 3.5vh 2vw;
    font-size: 1.4vw;
    cursor: pointer;
}

.dbr-mob-menu {
    display: none;
}


.dbr-nav-list ul li a {
    /* color: #000;  Default color */
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}
.dbr-nav-list ul li a:hover {
    color: #666; /* Hover color */
}

.dbr-nav-list ul li a.active-link {
    color: #ff5722; /* Active link color */
    font-weight: bold;
    text-decoration: underline;
}






@media (max-width: 650px) {
    .dbr-nav {
        background-color: #1F1515;
    }
    .dbr-logo-holder img {
        width: 30vw;
        margin-left: 7vh;
    }
    .dbr-nav-list ul {
        display: none;
    }
    .dbr-mob-menu {
        display: block;
        width: 7vw;
        height: auto;
        margin-top: 1.7vh;
        margin-right: 7vw;
    }

    
    


}