/** @format */

@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 0;
  margin: 0;
  color: #fdf0d5;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

/* web dashboard environment starts here */
.dashboardAccount {
  width: 100%;
  height: 100vh;

  background-color: rgba(253, 240, 213, 1);
}

.dashboardAccount-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.dashAccount-guest-section {
  width: 25%;
  height: inherit;
}
.dashAccount-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.dashAccount-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashAccount-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.dashAccount-content-body {
  width: 100%;
  height: 90vh;
}
.dashAccount-content-body p {
  color: rgba(31, 21, 21, 1);
  font-size: 2vw;
  height: 8vh;
  display: flex;
  align-items: center;
  margin-left: 5vw;
}
.dashAccount-content-table-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* web Dashboard environment end here */

/* slide in fade starts here */
.hidden {
  /* hidden */
  opacity: 0;
  filter: blur(1em);
  transform: translateX(+100%);
  transition: all 1s;
}
.hidden-left {
  /* hidden */
  opacity: 0;
  filter: blur(1em);
  transform: translateX(-100%);
  transition: all 1s;
}
.hidden-down {
  opacity: 0;
  filter: blur(1em);
  transform: translateY(100%);
  transition: all 1s;
}
.hidden-up {
  opacity: 0;
  filter: blur(1em);
  transform: translateY(-100%);
  transition: all 0.5s;
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}
/* slide in fade ends here */

/*  */
.landing-page {
  max-width: 100%;
}
/*  */
