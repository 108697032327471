.gallery{
    max-width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1f1515;
}
.text{
    width: 46.9%;
    height: inherit;
    text-align: right;
    display: flex;
    flex-direction: column;
    
}
.gallery-text-board {
    text-align: right;
    height: 40vh;
    margin: auto 0;
    display: flex;
    flex-direction: column;
}
.text h2{
    width: 33vw;
    height: 30vh;
    font-family: Georgia;
    font-size: 4vw;
    font-weight: normal;
    margin: 1.8vw 0 0 10.3vw;
    color: #fdf0d5;
    text-align: right;
}
.text p{
    width: 34.7vw;
    height: 10.6vh;
    margin-left: 8vw;
    font-family: Georgia;
    font-size: 1.3vw;
    font-weight: normal;
    text-align: right;
    color: #fdf0d5;
    /* line-height: 1.5; */
}
.grid-container{
    width: 53.1%;
    height: inherit;
    display: flex;
}
.inner-gird-container {
    width: 46.75vw;
    height: 70vh;
    float: left;
    display: flex;
    gap: 20px;
    margin-top: 14vh;
    
}
.left-column{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.small-box{
    display: flex;
    border: thin solid #000;
    width: 16vw;
    height: 33vh;

}
#one{
    background-image: url('../../assets/Ropa5.png');
    background-position: center;
    background-size: cover;
    transform: scaleX(-1);
}
#two{
    background-image: url('../../assets/Ropa9.png');
    background-position: center;
    background-size: cover;
    transform: scaleX(-1);
}
#three{
    background-image: url('../../assets/Ropa10.png');
    background-position: center;
    background-size: cover;
    transform: scaleX(-1);
}
/* .small-box img {
    width: inherit;
    height: inherit;
    transform: scaleX(-1);
} */
.right-column {
    width: 29.1vw;
    height: 70vh;
    background-image: url('../../assets/Ropa11.png');
    background-position: center;
    background-size: cover;
    transform: scaleX(-1);
}
/* .right-column img {
    width: inherit;
    height: inherit;
    transform: scaleX(-1);
} */

@media (max-width: 1000px) {
    .gallery{
        height: 80vh;
    }
    .inner-gird-container {
        height: 56vh;

    }

    .right-column {
        height: 56vh;
    }
    .gallery-text-board {
        height: 20vh;
        gap: 2vh;
    }
}
@media (max-width: 650px) {
    .gallery{
        flex-direction: column-reverse;
        height: 100vh;
    }
    .text h2{
        font-size: 5.1vw;
        width: 78.4vw;
        height: 7vh;
        margin: auto;
        text-align: center;
    }
    .text{
        width: 100%;
        height: inherit;
        text-align: right;
        height: 20vh;
    }
    .text p{
        width: 81vw;
        height: 9.6vh;
        margin: auto;
        font-size: 2.6vw;
        text-align: center;
    }
    .grid-container{
        width: 100%;
        height: 80vh;
        display: flex;
    }
    .inner-gird-container {
        margin: auto;
        width: 82.2vw;
        height: 70vh;
        /* background-color: blue; */
        margin-top: 10vh;
    }
    .right-column {
        width: 47.4vw;
        height: 65vh;
    }
    .right-column img {
        width: inherit;
        height: inherit;
    }
    .small-box  {
        width: 26.2vw;
        height: 20vh;
    }
    
}

@media (max-width: 450px) {
    .gallery{
        flex-direction: column-reverse;
        height: 70vh;
    }
    .text {
        height: 15vh;
    }
    .gallery-text-board {
        height: 15vh;
        justify-content: space-around;
    }
    .gallery-text-board h2 {
        height: 4vh;
        /* background-color: green; */
    }
    .gallery-text-board p {
        height: 11vh;
        /* background-color: blue; */
    }

    .grid-container{
        height: 55vh;
    }
    .inner-gird-container {
        height: 55vh;
        margin-top: 7vh;
    }
    .right-column {
        height: 45vh;
    }
    .right-column img {
        width: inherit;
        height: inherit;
    }
    .small-box  {
        /* width: 26.2vw; */
        height: 13.3vh;

    }
}