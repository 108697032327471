.assignRoom {
  width: 100%;
  height: 100vh;
  background: url(../../../../assets/assign-room.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.assignRoom-guest-id input::placeholder {
  font-size: 14px; /* Reduce the placeholder font size */
}
/* Ensure spinner rotates */
.animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.assignRoom-hue {
  width: 100%;
  height: 100vh;
  display: flex;
  background: rgba(31, 21, 21, 0.9);
}
.assignRoom-head {
  width: 25%;
  height: inherit;
}
.assignRoom-main-body {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.assignRoom-mobile-head {
  display: none;
}
.assignRoom-content-section{
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.assignRoom-body {
  width: 50%;
  margin: auto;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 10vh;
  /* gap: 30px;  Space between child elements */
}
.assignRoom-body-top {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
}
.assignRoom-body-top h3 {
  font-weight: normal;
  font-size: 1.7vw;
}
.assignRoom-body-top input {
  font-size: 4.5vw;
  width: 40%;
  text-align: center;
  font-weight: lighter;
  font-style: italic;
  opacity: 0.6;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: thick solid #fdf0d5;
}
.assignRoom-body-middle {
  height: 10vh;
  width: 67%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}
.assignRoom-body-middle p {
  font-size: 1.7vw;
  margin: auto;
}
.assignRoom-middle-checkbox {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.assignRoom-middle-checkbox label {
  font-size: 1.3vw;
}
.assignRoom-middle-checkbox label input {
  margin-left: 1vw;
  font-size: 1.3vw;
}
.assignRoom-middle-checkbox label input[type="checkbox"]:checked {
  background-color: red;
  border-color: red;
}
.assignRoom-body-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  width: 100%;
  margin-top: 30px;
}
.assignRoom-body-bottom button {
  width: 100%;
  height: 6vh;
  color: rgba(31, 21, 21, 1);
  border: none;
  border-radius: 0.8vw;
  background-color: rgba(253, 240, 213, 1);
  font-size: 1.3vw;
}
.assignRoom-body-bottom a {
    width: 80%;
}
.assign_date {
    color: #fdf0d5;
    opacity: 1;
    display: block;
    background: url(../../../../assets/calendar_icon.svg) no-repeat ;
    -webkit-appearance: none;
    background-position: right;
    outline: none;
    border: none;
    border-width: thin;
    margin-left: 48%;
    font-size: 1.3vw;
}
.Assign_date[type="date"]::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(../../../assets/calendar_icon.svg) no-repeat transparent;
    width: 20px;
    height: 20px;
    border-width: thin; */
    display: none;
}
.assign_date::-webkit-calendar-picker-indicator {
    /* display: none; */
    /* -webkit-appearance: none; */
}



/* .assign_date::after {
  content: url("../../../assets/calendar_icon.svg");
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
} */
.assignRoom-input-id{
  display: flex;
  justify-content: center;
  align-items: center;
}
.assignRoom-input-id label {
  display: none;
}


@media (max-width: 650px) {
  .assignRoom  {
    background: url(../../../../assets/Staff-receptionist-mobile.jpg);
  }
  .assignRoom-head {
        display: none;
    }
  .assignRoom-body {
    width: 90%;
    height: auto;

    margin-top: 0vh;
  }
  .assignRoom-mobile-head{
    display: block;
  }
  .assignRoom-content-section {
    width: 100%;
  }
  .assignRoom-body-bottom {
    margin-right: 20px;
  }
  .assignRoom-body-top {
    width: 100%;
    height: auto;
    margin-top: 10vh;
  }
  .assignRoom-body-top h3 {
    font-size: 5vw;
  }
  .assignRoom-body-top input {
    /* font-size: 5vw; */
    /* border-bottom: 0.4vh; */
  }
  .assignRoom-input-id {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 0.2vh solid #FDF0D5;
    padding: 1vh 0;
    margin-top: 5vh;
  }
  .assignRoom-input-id label {
    display: block;
    font-size: 4vw;
    padding: 0 2vw 0 0;
  }
  .assignRoom-input-id input {
    flex-grow: 1;
    border: none;
    text-align: left;
  }
  .assignRoom-input-id input::placeholder {
    color: #FDF0D5;
  }
  .assignRoom-body-middle {
    width: 100%;
    margin-top: 5vh;
    margin-left: 10%;
    height: 20vh;
  }
  .assignRoom-body-middle p {
    font-size: 5vw;
    /* margin-top: 5vh; */
    margin-left: 0;
  }
  .assignRoom-middle-checkbox {
    margin-left: 0;
    justify-content: flex-start;
    gap: 10%;
  }
  .assignRoom-middle-checkbox label {
    font-size: 4vw;
    display: flex;
    align-items: center;
  }
  .assign-date-container input {
    font-size: 4vw;
    margin: 0;
  }
  .assignRoom-body-bottom {
      margin-top: 15vh;
  }
  .assignRoom-body-bottom button {
    font-size: 4vw;
  }
}
