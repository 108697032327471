.signIn {
    background: url(../../../assets/register-img.jpg); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; /* Optional: Center the image */
    width: 100%;
    height: 100vh;
}
.signIn-hue {
    background-color: rgba(31, 21, 21, 0.9);
    width: inherit;
    height: inherit;
}
/* Ensure spinner rotates */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.signIn-body {
    width: inherit;
    height: 80vh;
}
.signIn-form {
    margin: auto;
    width: 60%;
    height: 50vh;
}
.signIn-form h1 {
    display: block;
    margin: auto;
    margin: 4vh 1vw;
    font-size: 4vw;
    font-weight: normal;
    text-align: center;
}
.signIn-hide-box {
    height: 40vh;
}
.signIn-form input, .signIn-form button {
    
        display: block;
        margin: auto;
        width: 90%;
        height: 6.7vh;
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: thin solid #fdf0d5;
        margin: 4vh 2vw;
        font-size: 1.3vw;
}
.signIn-form input::placeholder {
    color: #fdf0d5;

}

.signIn-submit {
    background-color: #fdf0d5 !important;
    color: #1f1515;
    border-radius: 1vw;
    cursor: pointer;
}

@media (max-width: 650px) {
    .signIn-form input::placeholder {
        font-size: 2.3vw;
    }
    .signIn-form input {
        font-size: 2.3vw;
    }
    .signIn-form {
        width: 80%;
    }
    .signIn-submit {
        font-size: 2.3vw;
    }
    /* */
    .signIn-form button {
        font-size: 3.5vw;
    }
    .signIn-form h1 {
        text-align: center;
        font-size: 9vw;
    }
    .signIn-form div span {
        font-size: 2.3vw;
    
    }
    .signIn-file-label {
        width: 20vw;
        /* height: 4vh; */
        font-size: 2.3vw;
    }
}




@media (max-width: 450px) {

    .signIn-form {
        
        width: 90%;
        
    }
    .signIn-form input::placeholder {
        font-size: 4.3vw;
    }
    .signIn-form input {
        font-size: 4.3vw;
    }
    .signIn-submit {
        font-size: 10.3vw;
    }
    .signIn-logo-holder img {
        width: 25vw;
    }
    .signIn-form h1 {
        font-size: 8vw;
    }
    .signIn-form div span {
        font-size: 4.3vw;
    
    }
    .signIn-file-label {
        width: 30vw;
        
        font-size: 4.3vw;
    }
}