.Receipt * {
    color: #402F2F;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #ff0000;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px; /* Space between spinner and text */
}
.Spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.Spinner {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.Receipt-header {
    background-color: #402F2F;
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Receipt-container {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Receipt-container img {
    width: 40vh;
}
.Receipt-head-container {
    width: 30%;
}
.Receipt-container h1 {
    color: #ffffff;
    font-size: 4vw;
}
.Receipt-address {
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Receipt-address-container {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Receipt-address-container-right, .Receipt-address-container-left {
    width: 30%;
}
.Receipt-address-container-left {
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 2vh;
}
.Receipt-address-container-left p {
    font-size: 1.2vw;
}
.Receipt-address-container-right {
    display: flex;
    flex-direction: column;
    /* width: 80%; */
    gap: 1vh;
}

.Receipt-address-container-right p {
    font-size: 1.2vw;
}
.Receipt-address-container-right h2 {
    font-size: 1.6vw;
}
.Receipt-body {
    width: 100%;

}
.Receipt-table {
    width: 85%;
    margin: auto;

}
.Receipt-table-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #402F2F;
    height: 7vh;
}
.Receipt-table-head p {
    color: #ffffff;
    height: 7vh;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
    font-weight: bold;
}
.Receipt-table-body {
    width: 100%;
    height: auto;
    margin-bottom: 10vh;
}
.Receipt-table-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    height: 7vh;
}
.Receipt-table-row p{
    height: 7vh;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2vw;
}
.Receipt-table-row:nth-child(even) {
    background-color: #ECECEC;
}

.Receipt-footer {
    width: 85%;
    height: 45vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-bottom: 20vh;
}

.Receipt-footer-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    height: inherit;
}

.Receipt-policy {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    height: 25vh;
    
}
.Receipt-policy-header {
    font-size: 1.5vw;
}
.Receipt-policy-text {
    font-size: 1.2vw;
}

.Receipt-bank-detail p {
    font-size: 1.2vw;
    font-weight: bold;
    margin-bottom: .5vh;
}
.Receipt-footer-right {
    width: 35%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.Receipt-acc {
    width: 100%;
    display: flex;
    /* justify-content: flex-end; */
    /* align-items: center; */
}

.Receipt-acc-board {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

.Receipt-acc-board div p {
    padding: 1vh 1vw;
}
.Receipt-acc-title p {
    color: #ffffff;
    background-color: #402F2F;
}
.Receipt-acc-amount p {
    background-color: #ECECEC;
    
    border-bottom: 0.2vh solid #ffffff;
}

.Receipt-hat-container {
    display: flex;
    justify-content: flex-end;
}
.Receipt-hat-container img {
    width: 10vw;
}