.guestRoom {
    background: #FDF0D5;
}

.Gust-room-web {
    width: 100%;
    height: 100vh;
    display: flex;
}
.dbrw-guest-section {
    width: 25%;
    height: inherit;
    background: #FDF0D5;
}
.dbrw-content-section {
    width: 75%;
    height: inherit;
    color: rgba(31, 21, 21, 1);

}
.dbrw-content-head {
    width: 100%;
    height: 10vh;
    border-bottom: 0.2vh solid #1F1515;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dbrw-content-head a {
    color: rgba(31, 21, 21, 1);
    margin-right: 7vh;
    font-size: 1.05vw;
}
.dbrw-content-body {
    width: 100%;
    height: 90vh;
}
.dbrw-content-body p {
    color: rgba(31, 21, 21, 1);
    font-size: 2vw;
    height: 8vh;
    display: flex;
    align-items: center; 
    margin-left: 5vw;
}
.dbrw-content-table-section {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center; 
    justify-content: center;
    
}
.dbrw-web-table-holder {
    width: 90%;
    height: 70vh;
    background-color: rgba(255, 250, 238, 1);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) ;
}
.dbrw-web-table {
    width: 95%;
    height: auto;
    margin: auto;
    /* border: thin solid #000; */
}
.dbrw-web-table tr {
    border: thin solid #000;
    height: 7vh;

}
.dbrw-web-table .thead {
    border-bottom: 0.2vh solid #000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.dbrw-web-table .thead p{
    color: #1F1515;
    font-weight: 700;
    font-size: 1.55vw;
    width: 30%;
}

.dbrw-web-table tr td div {
    color: #1F1515;
    /* text-align: center; */
}