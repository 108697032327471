/** @format */

.ReceptioistManageProfile {
    background: rgba(31, 21, 21, 1);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5vh;
  }
  .ReceptioistManageProfile-upper-sec {
    height: 40vh;
    width: 100%;
  }
  .ReceptioistManageProfile-logo {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ReceptioistManageProfile-logo img {
    width: 12vw;
  }
  .ReceptioistManageProfile-profile {
    width: 100%;
    height: 30vh;
    background: rgba(64, 47, 47, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 5vh 0;
  }
  .ReceptioistManageProfile-profile h2 {
    font-size: 1.8vw;
    font-weight: normal;
  }
  .ReceptioistManageProfile-profile p {
    font-size: 1vw;
    font-weight: normal;
  }
  .ReceptioistManageProfile-lower-sec {
    height: 60vh;
    width: 100%;
  }
  .ReceptioistManageProfile-lower-sec ul {
    width: 100%;
  }
  .ReceptioistManageProfile-lower-sec ul a {
    width: 100%;
    color: rgba(255, 255, 255, 1);
    display: flex; /* Make the li a flex container */
    align-items: center;
    height: 6vh;
    list-style: none;
  }
  .hover-gdb-btn {
    background: rgba(64, 47, 47, 1) !important;
  }
  .ReceptioistManageProfile-lower-sec ul a li {
    margin: auto;
    font-size: 1.4vw;
  }
  .ReceptioistManageProfile-lower-sec ul a:hover {
    background-color: rgba(64, 47, 47, 1);
  }
  
  .gdb-profile {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }
  .gdb-profile img {
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    align-items: center;
  }
  
  .logout-btn {
    color: #fdf0d5;
    background-color: transparent;
    width: 100%;
    height: 6vh;
    
    margin-top: 20px;
    font-size: 1vw;
    margin: auto;
    cursor: pointer;
    border: 0;
  }
  .logout-btn:hover {
    background-color: #402F2F;
  }
  
  .ReceptioistManageProfile-lower-sec ul li button {
    text-align: center;
    font-size: 1.4vw;
  }
  .ReceptioistManageProfile-login {
    width: 100%;
    color: #fdf0d5; 
    display: flex; /* Make the li a flex container */
    align-items: center;
    justify-content: center;
    height: 6vh;
    list-style: none;
  }
  .ReceptioistManageProfile-login:hover {
    background-color: rgba(64, 47, 47, 1);
  }