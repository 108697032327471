.OnlineBookingListItem {
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #1F1515;
    border-bottom: 0.2vh solid #1F1515;
    height: 7vh;
    align-items: center;
}
.OnlineBookingListItem p {
    color: #1F1515;
    font-size: 1.3vw;
    width: 20%;
}
.OnlineBookingListItem-break {
    display: none;
}

@media (max-width: 650px) {
    .OnlineBookingListItem {
        align-items: flex-start;
        background-color: #FFFAEE;
        height: 13vh;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 2vh 3vw;
        border: none;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    }
    .OnlineBookingListItem p {
        font-size: 3.7vw;
        margin-left: 1rem;
        width: auto;
    }
    .OnlineBookingListItem-phone {
        position: absolute;
        top: 0;
        right: 0;
        margin: 2vh 5vw 0 0;
        /* margin-left: 1rem; */
    }
        

    
}