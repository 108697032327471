.registerGuests {
    position: relative; 
    width: 100%;
    height: 100vh;
    background-image: url(../../../../assets/booking_bg.jpg);
    background-repeat: no-repeat;
    background-position: center ;
    background-size: cover;
}
.registerDashboard-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.registerGuests-head {
  width: 25%;
  height: inherit;
}
.registerGuests-mobile-head {
  display: none;
}
.registerGuests-hue {
    background-color: rgba(31, 21, 21, 0.9);
    width: 100%;
    height: 100vh;
    display: flex;
}
/* Ensure spinner rotates */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
/* Truncate file name */
.truncate {
  max-width: 200px; /* Adjust as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
.registerGuests-body {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.registerGuests-body-content-holder {
    width: 85%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
}
.registerGuests-body-content-holder h1 {
    font-weight: normal;
    font-size: 5vw;
    margin-top: 3vw;
}
.registerGuests-form {
    width: 80%;
    height: 90vh;
}
.registerGuests-form-holder {
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: space-between;
    
}
.registerGuests-form-100p {
    width: 100%;
    height: 6vh;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.3vw;
}
.registerGuests-form-100p[type="text"] {
    border-bottom: thin solid rgba(253, 240, 213, 1);
}
.registerGuests-form-100p[type="text"]::placeholder {
    color: rgba(253, 240, 213, 1);
    font-size: 1.3vw;
}
.registerGuests-form-holder label {
    /* border-bottom: 0.2vh solid rgba(253, 240, 213, 1) ;
    height: 5vh;
    font-size: 1.3vw;
    width: 48%;
    display: flex;
    align-items: center; */
}
.registerGuests-form50p {
    /* width: 100%; */
    height: 5vh;
    background-color: transparent;
    outline: none;
    border: none;
    /* border-bottom: thin solid rgba(253, 240, 213, 1); */
    font-size: 1.3vw;
}
.registerGuests-form50p::placeholder {
    color: rgba(253, 240, 213, 1);
    font-size: 1.3vw;
    
}
.registerGuests-form25p {
    width: 23%;
    height: 6vh; 
    background: rgba(253, 240, 213, 1);
    outline: none;
    border: none;
    color: rgba(31, 21, 21, 1);
    border-radius: 0.5vw;
    font-size: 1.3vw;
}
.registerGuests-form-100p[type="submit"] {
    background: rgba(253, 240, 213, 1);
    color: rgba(31, 21, 21, 1);
    font-size: 1.2vw;
}
.registerGuests-half-text-enter {
    width: 47%;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: thin solid rgba(253, 240, 213, 1);
}
.registerGuests-half-text-enter label {
    font-size: 1.3vw;
}
.registerGuests-half-text-enter .registerGuests-form50p {
    flex-grow: 1;
}
.registerGuests-passport .registerGuests-file-label {
    border: thin solid rgba(253, 240, 213, 1);
    /* padding: 1vh 2vw; */
    width: 40%;
    height: 5vh;
    font-size: 1.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: right;
}

.registerGuests-passport {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 47%;
    border-bottom: 0.2vh solid rgba(253, 240, 213, 1);
}
.registerGuests-passport span {
    
}
.registerGuests-mobile-head {
    display: none;
}

@media (max-width: 650px) {
    .registerGuests-head {
        display: none;
    }
    .registerGuests-mobile-head {
        display: block;
    }
    .registerGuests-body-content-holder {
        width: 95%;
    }
    .registerGuests-form-holder {
        flex-direction: column;
        height: auto;
        width: 100%;
    }
    .registerDashboard-content-section {
        width: 100%;
    }
    .registerGuests-half-text-enter {
        height: auto;
        width: 100%;
        margin: 1vh 0;
    }
    .registerGuests-half-text-enter label, .registerGuests-passport span {
        font-size: 3vw;
        color: rgba(253, 240, 213, 1);
        font-weight: normal;

    }
    .registerGuests-passport {
        width: 100%;
    }
    .registerGuests-form-holder .registerGuests-form-100p {
        font-size: 4vw;
    }
    .registerGuests-passport .registerGuests-file-label {
        font-size: 3vw;
        height: 4vh;
        width: 30%;
    }
    .registerGuests-body {
        height: auto;
    }
    .registerGuests-body-content-holder {
        height: auto;
    }
    .registerGuests {
        height: auto;
    }
    .registerGuests-hue {
        height: auto;
        padding-bottom: 15vh; 
    }

}