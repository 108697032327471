.GuestDashBoardRoom {
  width: 100%;
  height: 100vh;
  background-color: rgba(253, 240, 213, 1);
}
.spinner {
  animation: spin 1s linear infinite;
  font-size: 1.2rem; /* Adjust size as needed */
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.status-button {
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}
.status-button.available {
  background-color: #4caf50;  /* Green */
  color: white;
}
.status-button.unavailable {
  background-color: #f44336; /* Red */
  color: white;
  cursor: not-allowed;
}
.loading-message,
.error-message {
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  color: #ff0000; /* Customize as needed */
}
.GuestDashBoardRoom-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.GuestDashBoardRoom-profile-section {
  width: 25%;
  height: inherit;
}
.GuestDashBoardRoom-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.GuestDashBoardRoom-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.GuestDashBoardRoom-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.GuestDashBoardRoom-content-body {
  width: 100%;
  height: 90vh;
}
/* .GuestDashBoardRoom-content-body p {
  color: rgba(31, 21, 21, 1);
  font-size: 2vw;
  height: 8vh;
  display: flex;
  align-items: center; 
  margin-left: 5vw;
} */

/* .GuestDashBoardRoom-content-table-section {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
.GuestDashBoardRoom-center-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.GuestDashBoardRoom-content-table-section {
  width: 90%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #ececec;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
}

.GuestDashBoardRoom-option-details {
  width: 90%;
  height: 10vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.GuestDashBoardRoom-account-title {
  width: 90%;
  height: 10vh;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: none;
}

.GuestDashBoardRoom-option-details div {
  display: flex;
  justify-content: space-between;
}
.GuestDashBoardRoom-option-details .GuestDashBoardRoom-recep-detail button {
  background: #402f2f;
  color: #fffaee;
  font-size: 1.3vw;
  border: none;
  border-radius: 1.1vw;
  padding: 1vh 2vh;
  margin: 0 1vw;
}
.GuestDashBoardRoom-option-details .GuestDashBoardRoom-user-detail p {
  color: #402f2f;
  font-size: 2vw;
  margin-right: 2vw;
}
.GT-container {
  width: 100%;
  height: 55vh;
  overflow-y: scroll;
}
.GT-container p {
  color: #402f2f;
}
.GT-table-option {
  width: 100%;
  height: auto;
}
.GT-option-Button {
  width: 100%;
  background: rgba(255, 250, 238, 1);
  height: 6vh;
  display: flex;
  align-items: center;
}
.GT-option-Button div {
  width: 15%;
  display: flex;

  margin-left: 3vw;
}
.GT-option-Button div img {
  width: 0.7vw;
}

.GT-option-Button div p {
  font-size: 1.2vw;
  margin: 0 0 0 1vw;
  height: auto;
}

.GT-option-Body {
  width: 100%;
  height: auto;
}
.GT-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.GT-Table-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: thin solid rgba(64, 47, 47, 0.09);
  height: 6vh;
  background: #ececec;
}
.table-row-header p {
  font-weight: 800;
}
.GT-Table-row p,
.GT-Table-row div {
  margin: auto;
  width: 20%;
  font-size: 1.2vw;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  text-align: left;
  margin-left: 5%;
}
.GT-Table-row div p {
  margin: 0;
}

.GT-Balance {
  height: 15vh;
  width: 100%;
  background-color: #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GT-Balance h2 {
  color: #402f2f;
  font-size: 1.8vw;
  margin-left: 2vw;
}

.GT-Balance-right {
  display: flex;
  justify-content: space-between;
}
.GT-Balance-square {
  margin: 0 2vw 0 2vw;
}
.GT-Balance-square p {
  color: #000;
  font-size: 1.3vw;
  font-weight: 600;
}
.GT-Balance-square .red {
  color: red;
}

.GT-Balance-square .green {
  color: green;
}
.GT-table-optioin {
  width: 100%;
  height: fit-content;
}
.GuestDashBoardRoom-mob-row p:nth-child(1) {
  display: none;
}

.clicked-class {
  transform: rotate(90deg);  
  transition: transform 0.3s ease-in-out;
}

.clicked-back {
  transform: rotate(0deg);  
  transition: transform 0.3s ease-in-out;
}
.GuestDashBoardRoom-mobile-header {
  display: none;
}

/* Mobile Styling here */
@media (max-width: 650px) {
  .GuestDashBoardRoom-profile-section {
    display: none;
  }
  .GuestDashBoardRoom-mobile-header {
    display: block;
  }
  .GuestDashBoardRoom-content-section {
    width: 100%;
  }
  .GuestDashBoardRoom-content-head {
    display: none;
  }
  .GuestDashBoardRoom-option-details .GuestDashBoardRoom-user-detail {
    display: none;
  }
  .GuestDashBoardRoom-account-title {
    display: flex;
    margin-top: 3vh;
  }
  .GuestDashBoardRoom-account-title h2 {
    font-size: 6vw;
    color: #1f1515;
    font-weight: normal;
  }
  .GuestDashBoardRoom-option-details {
    width: 80%;
  }
  .GuestDashBoardRoom-recep-detail {
    width: 100%;
    margin-top: 5vh;
  }
  .GuestDashBoardRoom-option-details .GuestDashBoardRoom-recep-detail button {
    font-size: 4vw;
    border-radius: 4vw;
  }
  .GT-option-Button div {
    width: 25%;
    gap: 7%;
    margin-left: 10%;
  }
  .GT-option-Button div img {
    width: 3vw;
  }
  .GT-option-Button div p {
    font-size: 3vw;
  }
  .table-row-header {
    display: none;
  }
  .GT-Table-row {
    flex-direction: column;
    height: auto;
  }
  .GuestDashBoardRoom-content-body {
    height: 100vh;
  }
  .GuestDashBoardRoom{
    height: auto;
  }
  .GuestDashBoardRoom-web{
    height: auto;
  }
  .GT-Table-row p {
    font-size: 4vw;
    width: auto;
    color: #1f151599;
  }
  /* .GT-Table-row p:nth-child(1) { */
  .GT-Table-row .first-item {
    width: 100%;
    background-color: #a3a3a3;
    color: #1f1515;
    font-size: 4vw;
    margin: 0;
    padding: 1vh 10%;
  }
  .GuestDashBoardRoom-mob-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.2vh solid #402f2f17;
    padding: 1vh 0;
  }
  .GT-Table-row div {
    width: 100%;
    justify-content: space-between;
    margin: 0;
  }
  .GuestDashBoardRoom-mob-row p {
    width: 45%;
    margin: 0;
  }
  .GuestDashBoardRoom-mob-row p:nth-child(1) {
    text-align: right;
    display: block;
  }
}
