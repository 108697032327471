.dashboard-room{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #FDF0D5;

}


.dropdown,.dropdown-btn, .dropdown-item-left,.dropdown-item-right{
    color: #1F1515;
    justify-content: center;
    border-top: 0.1px solid #ece0e0;
    
}
.dropdown-item-right p {
    color: #1f1515;
}
.dropdown-item-left img{
    width: 2.5vw;
    margin-right: 2vw;
    /* height: 2vw; */
}
.dropdown-item-left {
    /* justify-content: center; */
    align-items: center;
}
.dropdown-btn{
    font-weight: bold;
}
.dropdown-content{
    transform: 0.5s;
}

.rotate-icon {
    transform: rotate(90deg);
    transition: transform 0.3s ease; /* Optional: Add a transition for a smoother rotation effect */
}
.dashboard-room-mobile-header {
    display: none;
}
.dropdown-mobile-table {
    display: none;
}

/*web section starts here*/

.dashboard-room-web {
    width: 100%;
    height: 100vh;
    display: flex;
}
.dbrw-guest-section {
    width: 25%;
    height: inherit;
    
}
.dbrw-content-section {
    width: 75%;
    height: inherit;
    color: rgba(31, 21, 21, 1);

}
.dbrw-content-head {
    width: 100%;
    height: 10vh;
    border-bottom: 0.2vh solid #1F1515;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dbrw-content-head a {
    color: rgba(31, 21, 21, 1);
    margin-right: 7vh;
    font-size: 1.05vw;
}
.dbrw-content-body {
    width: 100%;
    height: 90vh;
}
.dbrw-content-body p {
    color: rgba(31, 21, 21, 1);
    font-size: 2vw;
    height: 8vh;
    display: flex;
    align-items: center; 
    margin-left: 5vw;
}
.dbrw-content-table-section {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center; 
    justify-content: center;
    
}
.dbrw-web-table-holder {
    width: 90%;
    height: 70vh;
    background-color: rgba(255, 250, 238, 1);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) ;
}
.dbrw-web-table {
    width: 95%;
    height: auto;
    margin: auto;
    /* border: thin solid #000; */
}
.dbrw-web-table tr {
    border: thin solid #000;
    height: 7vh;

}
.dbrw-web-table thead tr th{
    color: #1F1515;
    border-bottom: 0.2vh solid #000;
    font-size: 1.05vw;
}
.dbrw-web-table tbody tr td  {
    color: #1F1515;
    text-align: center;
    border-bottom: 0.2vh solid #000;
    font-size: 1.05vw;
}
.dbrw-web-table tr td div {
    color: #1F1515;
    /* text-align: center; */
}

/*web section eneds here*/

@media (max-width: 650px) {
    .dashboard-room-mobile-header {
        display: block;
    }
    .dashboard-room-web {
        display: none;
    }
    
    .dbr-header h2{
        color: #1f1515;
    }
    .dropdown-mobile-table {
        display: block;
        width: 90%;
        height: 75vh;
        /* box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) ; */
        /* margin: 7vw 3vw 4vw 4vw; */
        overflow-y: scroll;
    .drp img{
        width: 2vw;
        height: 2vh;
    }
    .dropdown {
        height: auto;
    }
    .dropdown-btn {
        background-color: #fffaee;
        width: 100%;
        height: auto;
        display: flex;
        padding: 3px;
    }
    .dropdown-item {
        background-color: #ececec;
        width: 100%;
        height: auto;
        display: flex;
        padding: 3px;
    }
    .dropdown-item-left {
        
        width: 50%;
        height: auto;
        display: flex;
        padding: 3px;
    }
    .dropdown-item-right {
        
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 3px;
    }
    
    
    }
}