.staff-content-table-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: flex-start;
  position: relative;
}
.reserve-dashboard-button-navigate {
  border: none;
  padding: 16px 24px; 
  background: rgba(31, 21, 21, 1); /* Updated background */
  color: #fff;
  border-radius: 1px;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  display: inline-block;
  text-align: center; /* Align text inside the button */
  box-sizing: border-box;
  border-radius: 1vw;
}
.reserve-option-btn-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.reserve-dashboard-button-navigate::center {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(50deg, transparent, gold, transparent);
}
.reserve-dashboard-button-navigate span {
  position: relative;
  background: linear-gradient(90deg, #d4af37, #ffd700, #d4af37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.navigation-button {
  position: absolute;
  right: 0; /* Aligns it to the left */
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10vh;
}


.navigate-reservation-btn:hover {
  background-color: #45a049;
}

.StaffDashboard {
  width: 100%;
  height: 100vh;
  position: relative; 
  background-color: rgba(253, 240, 213, 1);
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error-message {
  color: red;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}
.StaffDashboard-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.StaffDashboard-guest-section {
  width: 25%;
  height: inherit;
}
.StaffDashboard-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.StaffDashboard-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.StaffDashboard-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.StaffDashboard-content-body {
  margin-left: 3rem;
  width: 90%;
  height: 75vh;
}
.StaffDashboard-content-body p {
  margin: 0;
  width: auto;
}
.StaffDashboard-content-body p {
  color: rgba(31, 21, 21, 1);
  font-size: 2vw;
  height: 8vh;
  display: flex;
  align-items: center;
  margin-left: 5vw;
}

.staff-dashboard-button {
  border: none;
  padding: 2vh 3vw;
  background-color: rgba(64, 47, 47, 1);
  color: rgba(253, 240, 213, 1);
  border-radius: 1vw;
  font-size: 1.5vw;
  margin: 0 1rem;
}
.staff-dashboard-button-bookings {
  border: none;
  padding: 2vh 3vw;
  background-color: rgba(64, 47, 47, 1);
  color: rgba(253, 240, 213, 1);
  border-radius: 1vw;
  font-size: 1.5vw;
  margin: 0 1rem;
  position: relative;
  display: inline-block;
}


.staff-room-btn-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
  height: auto;
  min-height: 50vh; /* Ensures it starts at viewport height */
  overflow-y: visible; /* Content can expand */
  padding: 1rem;
  box-sizing: border-box;
}

.hover-gdb-btn {
  background: rgba(64, 47, 47, 1) !important;
}
.staff-apartments {
  width: 100%;
  height: auto;
  padding: 7vh 0 0 0;
}
.staff-apartment-btn-section {
  overflow-y: scroll;
  height: 13vh;
}
.room-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 16px; /* Add spacing between boxes */
}
.staff-option-btn-section {
  border-top: 0.2rem solid rgba(64, 47, 47, 1);
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
}
/* button notification */

.Bookings-notification {
  width: 100%;

  padding: 4vh 0 0 0;
}
.button-StaffDashboard {
  background-color: rgba(64, 47, 47, 1);
  border: none;
  color: #fdf0d5;
  width: 20%;
  padding: 2vh 3vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5vw;
  border-radius: 1vw; /* Make the button circular */
  margin: 0 1rem;
}

.button-text {
  position: relative; /* Position the notification */
}

.notification {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(64, 47, 47, 1);
  color: white;
  border: 0.1vw solid #fdf0d5;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.StaffDashboard-mobile-header {
  display: none;
}

@media (max-width: 650px) {
  .StaffDashboard-guest-section {
    display: none;
  }
  .navigation-button {
    bottom: 150px;
  }
  .StaffDashboard-content-head {
    display: none;
  }
  .StaffDashboard-mobile-header {
    display: block;
  }
  .StaffDashboard-content-section {
    width: 100%;
  }
  .StaffDashboard-content-body {
    margin-left: 7%;
    height: 110vh;
  }
  .StaffDashboard {
    height: auto;
  }
  .StaffDashboard-web {
    height: auto;
  }
  .StaffDashboard-content-body p {
    font-size: 5vw;
    margin: 0;
  }
  .staff-content-table-section {
    height: auto;
  }
  .staff-room-btn-section {
    height: 40vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .staff-room-btn-section::-webkit-scrollbar {
    width: 0.2rem;
    background-color: #fdf0d5;
    border-radius: 1rem;
    
  }
  .staff-room-btn-section::-webkit-scrollbar-thumb {
    width: 0.2rem;
    background-color: #1f1515;
    border-radius: 1rem;
    
  }
  .staff-apartments {
    padding: 0;
  }
 
  .button-StaffDashboard {
    padding: 1vh 5vw 1vh 1vw;
    border-radius: 1vw;
} 
  .button-StaffDashboard .button-text {
    color: #fdf0d5;
    font-weight: normal;
    padding: 1vh 6vw 1vh 1vw;
  }
  .staff-option-btn-section {
    flex-direction: row; /* Ensure horizontal layout */
    justify-content: space-between; /* Adjust spacing between buttons */
    padding: 1rem; /* Reduce padding for smaller screens */
    height: auto; /* Remove fixed height for flexibility */
  }
  .staff-option-btn-section a {
    font-size: 3.5vw;
    padding: 2vh 5vw;
    border-radius: 4vw;
  }
  .button-StaffDashboard {
    width: 40%;
    font-size: 4vw;
  }
  .room-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
  }
}
