.dashboard-room-profile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #1F1515FA;
    ;
    /* visibility: hidden; */
}

.nav-links {
  list-style-type: none; /* Remove default bullet points */
  padding: 0;
  margin: 0;
}
.nav-links li {
  margin-bottom: 10px; /* Adjust this value for the space between links */
}

.profile-cont {
    width: 100%;
    height: 30vh;
    background-color:  #402F2F;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.profile-cont img {
    width: 15vw;
    height:15vw;
    border-radius: 50%;
    margin-top: 40px;
}
.profile-cont h4 {
    color: #FDF0D5;
    font-weight: normal;
    font-size: 2vw;
    padding: 10px;
}
.profile-cont p {
    font-size: 1.2vw;
    font-weight: normal;
}
.dbp-navbar {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.dbp-arrow-up {
    padding: 25px;
    position: relative;
}
.dbp-arrow-up img {
    position: absolute;
    top: 30px;
    right: 30px;
    align-items: center;
}
.dbp-logo {
    padding: 20px;
    height: auto;
    text-align: center;
}
.dbp-logo  img {
    width: 40vw;
    height: 7vh;
}
.dbp-nav-list {
    height: 26.7vh;
}
.dbp-nav-list ul {
    text-align: center;
    display: flex;
    flex-direction: column;
}
.dbp-nav-list ul button {
    
    font-size: 1.2vw;
    height: 5vh;
    width: 100;
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: block;
}
.dbp-nav-list ul button:hover {
    background-color: #402F2F;
}
/**/
.dashboard-room-profile.hide {
    visibility: hidden;
}
/**/
.GuestAccount-mobile-header {
    display: none;
}


@media (max-width: 650px) {
    /* .dashboard-room-profile.hide {
        visibility: hidden;
    } */
    .GuestAccount-mobile-header {
        display: block;
    }
    .profile-cont {
        width: 100%;
        height: 30vh;
        background-color:  #1F1515;
        text-align: center;
    }
    .profile-cont img {
        width: 15vw;
        height:15vw;
        border-radius: 50%;
        margin-top: 40px;
    }
    .profile-cont h4 {
        font-weight: normal;
        font-size: 25px;
        padding: 10px;
    }
    .profile-cont p {
        font-size: 15px;
        font-weight: normal;
    }
    .dbp-navbar {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .dbp-arrow-up {
        padding: 25px;
        position: relative;
    }
    .dbp-arrow-up img {
        position: absolute;
        top: 30px;
        right: 30px;
        align-items: center;
    }
    .dbp-logo {
        padding: 20px;
        height: auto;
        text-align: center;
    }
    .dbp-logo img {
        width: 40vw;
        height: 7vh;
    }
    .dbp-nav-list {
        height: 26.7vh;
    }
    .dbp-nav-list ul {
        text-align: center;
    }
    .dbp-nav-list ul button {
        
        font-size: 20px;
        height: 5vh;
        width: 100;
        cursor: pointer;
    }
    .dbp-nav-list ul button:hover {
        background-color: #402F2F;
        ;
    }
}