.AdminDashboard {
    width: 100%;
    height: 100vh;
    background-color: #FDF0D5;
    display: flex;

    position: relative; 
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 80%;
}

.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #d32f2f;
}

.AdminDashboard-profile {
    width: 25%;
    height: inherit;
    background-color: #1F1515;

}
.AdminDashboard-space {
    width: 75%;
    height: 100vh;
    background-color: #FDF0D5;
}
.AdminDashboard-space-board {
    height: 70vh;
    width: 100%;
    border-bottom: 0.3vh solid #1F1515;
    border-top: 0.3vh solid #1F1515;
    margin-top: 10vh;
    /* margin-left: 10%; */
}
.AdminDashboard-board {
    height: 25vh;
    width: 90%;
    margin-left: 5%;
    margin-top: 3vh;
}
.AdminDashboard-board p {
    font-size: 1.4vw;
    color: #1F1515;
}
.AdminDashboard-board-btnHolder {
    margin-top: 2vh;
    height: 20vh;
}
.AdminDashboard-board-btnHolder button {
    background-color: #402F2F;
    border: none;
    border-radius: 0.4vw;
    padding: 0.5vh 1vw;
    margin: 1vh 2vw;
    font-size: 1.4vw;
}
.AdminDashboard-mobile-header {
    display: none;
}

@media (max-width: 650px) {
    .AdminDashboard-mobile-header {
        display: block;
    }
    .AdminDashboard-profile {
        display: none;
    }
    .AdminDashboard-space {
        width: 100%;
    }
    .AdminDashboard-board p {
        font-size: 5vw;
    }
    .AdminDashboard-board-btnHolder {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: scroll;
    }
    .AdminDashboard-board-btnHolder button {
        font-size: 3.3vw;
        width: 30%;
        text-align: left;
    }
}