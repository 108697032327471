
.ReservedRoomBtn {
    border: none;
    padding-right: 1rem;
    background-color: rgba(64, 47, 47, 1);
    color: rgba(253, 240, 213, 1);
    border-radius: 0.4rem;
    font-size: 1.5rem;
    margin: 0.5rem 0.5rem;
}
.ReservedRoomBtn div {
    font-size: 1.1rem;
    margin: 0 0.3rem;
    float: left;
    
}


.ReservedRoomBtn span {
    font-size: 0.7rem;
    margin: 0 0.3rem;
}
@media (max-width: 650px) {
    .ReservedRoomBtn {
      /* padding: 1vh 7vw 0.5vh 0.5vw; */
      width: 90%;
      display: flex;
      align-items: center;
      gap: 1.5vw;
      border-radius: 1vw;
    }
    .ReservedRoomBtn div {
      font-size: 3vw;
      color: #fdf0d5;
    } 
    .ReservedRoomBtn span {
      font-size: 2.5vw;
      color: #fdf0d5;
      font-weight: normal;
    }
  }