.UpdateGuestPayment {
  position: relative; 
  background-image: url(../../../../assets/assign-room.png);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.updateGuestPayments-body {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.updateGuestPayment-mobile-head {
  display: none;
}
.updateGuestPayment-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
/* Ensure spinner rotates */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.UpdateGuestPayment-hue {
  width: 100%;
  height: 100vh;
  background: rgba(31, 21, 21, 0.9);
  display: flex;
}
.UpdateGuestPayment-head {
  width: 25%;
  height: inherit;
}
.UpdateGuestPayment-body {
  width: 50%;
  margin: auto;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UpdateGuestPayment-header-details {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  align-items: center;
  margin-top: 10vh;
}
.UpdateGuestPayment-header-details .UpdateGuestPayment-title {
  font-size: 1.3vw;
}
.UpdateGuestPayment-header-details h1 {
  text-decoration: underline;
  font-style: italic;
  font-weight: 500;
  font-size: 4.3vw;
  background-color: transparent;
  border: none;
  color: #fdf0d5;
  outline: none;
  text-align: center;
}
/* .UpdateGuestPayment-header-details input::placeholder {
    color: #fdf0d5;
} */
.UpdateGuestPayment-header-details .UpdateGuestPayment-staff {
  font-size: 2vw;
}

.room-dropdown {
  background-color: transparent;
  border: "1px solid #ccc";
  color: "#000";
  appearance: "none";
  padding: 8px;
}
.room-option {
  background-color: transparent;
  color: "#000";
}

.UpdateGuestPayment-header-details select {
  color: #fdf0d5;
  background: transparent;
  border: none;
  border: none;
  outline: none;
  font-size: 1.25vw;
  align-self: self-start;
  margin-left: 23.5%;
}
.UpdateGuestPayment-header-details #paymentCategory option {
  color: #fdf0d5;
  background: #1f1515;
}
.UpdateGuestPayment-header-details #paymentCategory option:hover {
  color: #1f1515;
  background: #fdf0d5;
}
.UpdateGuestPayment-form {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  margin-top: 5vh;
  width: 70%;
}

.UpdateGuestPayment-form textarea {
  background-color: transparent;
  border: thin solid #fdf0d5;
  outline: none;
  width: 100%;
  height: 10vh;
  padding: 1vh 1vw;
}
.UpdateGuestPayment-form textarea::placeholder {
  color: #fdf0d5;
  /* padding: 10px 0 0 10px; */
  font-style: italic;
  opacity: 0.6;
  font-size: 1.2vw;
}

.UpdateGuestPayment-form label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 1.2vw;
  border-bottom: 0.2vh solid #fdf0d5;
}
.UpdateGuestPayment-form div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 1.2vw;
  /* border-bottom: 0.2vh solid #FDF0D5; */
}

.UpdateGuestPayment-form input {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  /* border-bottom: thin solid #FDF0D5; */
  height: 3vh;
}
.UpdateGuestPayment-form input::placeholder {
  color: #fdf0d5;
  font-size: 1.2vw;
}

.UpdateGuestPayment-form button {
  background-color: #fdf0d5;
  color: #1f1515;
  border: none;
  padding: 1vh 0.2vw;
  font-size: 1.1vw;
  width: 100%;
}
.update_bill {
  background-color: #fdf0d5;
  color: #1f1515;
  padding: 1vh 3vw 1vh 1vw;
  margin-left: 1vw;
}
.UpdateGuestPayment-mobile-head {
  display: none;
}

@media (max-width: 650px) {
  .UpdateGuestPayment-body {
    width: 90%;
    height: 90vh;
  }
  .updateGuestPayments-body {
        height: auto;
    }
  .updateGuestPayment-mobile-head {
        display: block;
    }
  .updateGuestPayment-content-section {
        width: 100%;
    }
  .UpdateGuestPayment-header-details {
    width: 80%;
  }
  .UpdateGuestPayment-header-details h1 {
    /* font-size: 7vw; */
  }
  .UpdateGuestPayment-header-details .UpdateGuestPayment-staff {
    align-self: flex-start;
    font-size: 5vw;
  }
  .UpdateGuestPayment-header-details select {
    margin: 0;
    font-size: 5vw;
  }
  .UpdateGuestPayment-form {
    width: 80%;
    gap: 1vh;
  }
  .UpdateGuestPayment-head {
    display: none;
  }
  .UpdateGuestPayment-mobile-head {
    display: block;
  }
  .UpdateGuestPayment-header-details .UpdateGuestPayment-title {
    font-size: 6vw;
    margin-bottom: 3vh;
  }
  .UpdateGuestPayment-form button {
  }

  .UpdateGuestPayment-form button {
    font-size: 4vw;
    padding: 2vh 1vw;
  }
  .UpdateGuestPayment-form textarea {
    margin-bottom: 5vh;
    font-size: 5vw;
    padding: 1vh 4vw;
  }
  .UpdateGuestPayment-form textarea::placeholder {
    /* margin-bottom: 5vh; */
    font-size: 5vw;
  }
  .UpdateGuestPayment-form div {
    font-size: 5vw;
  }
  .UpdateGuestPayment-form label {
    margin-bottom: 5vh;
    font-size: 5vw;
  }
  .update_bill-holder {
    margin-bottom: 2vh;
  }
  .UpdateGuestPayment-hue {
    height: auto;
    padding-bottom: 10vh;
  }
}
