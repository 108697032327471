.room{
    display: flex;
    font-family: Georgia;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
}
.room-left{
    width: 43.3%;
    height: inherit;
}
.room-left img{
    /* height: inherit; */
    width: 100%;
    height: inherit;
}
.room-right{
    align-items: center;
    background: #1f1515;
    width: 56.7%;
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* gap: 0vh; */
}
.inner-top{
    width: 100%;
    
    text-align: left;
    /* background-color: darkgoldenrod; */

    display: flex;
    flex-direction: column;
    height: 40vh;
    gap: 5vh;
    margin-top: 13vh;
}
.room h1{
    width: 23vw;
    height: 24vh;
    font-family: Georgia;
    font-size: 5vw;
    font-weight: normal;
    display: block;
    margin-left: 11vw;
}

.room-right-text-board {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 13vh;
}
.room-right-text-board h3{
    width: 30vw;
    height: 6vh;
    font-family: Georgia;
    font-size: 1.3vw;
    margin-left: 11vw;
    color: #fdf0d5;

}
.room-right-text-board p{
    width: 23vw;
    height: 7vh;
    font-family: Georgia;
    font-size: 1vw;
    margin-left: 11vw;
}
.inner-button{
    width: 100%;
    height: 30vh;
    text-align: left;
    /* background-color: teal; */
}

.btn-div {
    width: 100%;
    height: 20vh;
}
.btn-div a {
    width: 9vw; 
    height: 9vw; 
    margin-left: 73%;
}
.circular-button {
    width: 9vw; 
    height: 9vw; 
    border-radius: 50%; /* Creates a circle */
    background-color: #d9d9d9; /* Set your desired background color */
    color: #1f1515; /* Set your desired text content color */
    border: none; /* Remove default button border */
    opacity: 1;
    font-size: 1.2vw;
}

.line{
    height: 0.011vh;
    width: 89.7%;
    background-color: #fdf0d5;
    margin-top: -2vh;
    position: absolute;
    right: 0;
} 
.room-con {
    width: 100%;
    height: 10vh;
    margin-top: -29px;
}
.room-con p{
    line-height: 1.8;
    font-size: 1.1vw;
    width: 14vw;
    height: 7vh;
    font-family: Georgia;
    color: #fdf0d5;
    margin-left: 11vw;
}

@media screen and (min-width: 1000px) or (not (orientation: landscape)) {
    .room{
        height: 80vh;
    }
    .inner-top {
        height: 30vh;
        gap: 0vh;
    }
}

@media (max-width: 650px) {
    .room{
        flex-direction: column-reverse;
        height: 170vh;
    }
    .room-left {
        width: 100%;
        height: 90vh;
    }
    .room-right {
        width: 100%;
        height: 80vh;
        gap: 9vh;
    }
    .inner-top {
        text-align: center;
        margin-top: 0vh;
    }

    .room h1{
        font-size: 9.5vw;
        margin: auto;
        text-align: center;
        height: 19vh;
        width: 57.4vw;
        margin-top: 73px;
    }
    .room-right h3{
        width: 100%;
        height: 7.13vh;
        font-size: 4.1vw;
        text-align: center;
        font-size: 22px;
        margin: auto;
        margin-top: 47px;
        font-weight: normal;
    }
    .room p {
        font-size: 2vw;
        margin: auto;
        text-wrap: wrap;
        text-align: center;
        height: 15vh;
        width: 45vw;
        line-height: 1.5;
    }
    .inner-button {
        height: 25vh;
        display: flex;
        flex-direction: column;
    }
    .btn-div {
        margin: auto auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .btn-div a {
        margin: auto;
        /* background-color: red; */
        width: 23vw;
        height: 23vw;
    }

    .circular-button {
        width: 23vw;
        height: 23vw;
        font-size: 3.3vw;
        margin: auto;
    }
    
    .line{
    display: none;
    }
    .room-con p {
    display: none;
    }
    .room-con {
        display: none;
    }
}
@media (max-width: 450px) {
    .room{
        height: 137vh;
    }
    .room-left {
        height: 70vh;
    }
    .room-right {
        height: 67vh;
        gap: 0vh;
    }
    .inner-top {
        height: 35vh;
        gap: 4vh;
    }
    .room h1{
        font-size: 9vw;
        height: 15vh;
        width: 57.4vw;
        margin-top: 7vh;
    }
    .room-right-text-board {
        height: 20vh;
    }
    .room h3{
        width: 50vw;
        height: 5vh;
        font-size: 4.1vw;
        text-align: center;
        margin-top: 0px;
    }
    .room p {
        font-size: 2.4vw;
        text-align: center;
        height: 7vh;
        width: 52vw;
        line-height: 1.8;
    }
    .inner-button {
        height: 22vh;
    }
    .btn-div {
        height: 25vh;
    }
    .btn-div a {
        height: 30vw;
        width: 30vw;
    }
    .circular-button {
        height: 30vw;
        width: 30vw;
        margin-top: -4vh;
        font-size: 5vw;
    }
}