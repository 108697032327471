.ManageGuest {
  width: 100%;
  height: 100vh;
  background-color: rgba(253, 240, 213, 1);
}
.ReceptionistManageGuest-thead {
  background-color: #f5f5f5; /* Add a background for better visibility */
  position: sticky; /* Keeps the header fixed when scrolling */
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  color: #000;
  border-bottom: 0.2vh solid #000;
}
.ReceptionistManageGuest-thead th {
  text-align: left; /* Align text to the left */
  color: black; 
  padding: 10px 75px; /* Increase padding for better spacing */
  border-bottom: 1px solid #ddd; /* Add a bottom border */
}
.names-column {
  width: 40%; /* Allocate more space for the Name column */
}
.uniques-no-column {
  width: 20%; /* Narrower Unique No column */
}
.contacts-column {
  width: 40%; /* Allocate more space for the Contact column */
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust this value to fit the table section height */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust to match table height */
  color: red;
  font-size: 16px;
  font-weight: bold;
}
.ManageGuest-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.ManageGuest-guest-section {
  width: 25%;
  height: inherit;
}
.ManageGuest-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.ManageGuest-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ManageGuest-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.ManageGuest-content-body {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ManageGuest-bar-holder {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}
.ManageGuest-bar-holder p {
  color: #1f1515;
  margin-left: 5vw;
  font-size: 2vw;
}
.ManageGuest-bar-holder input {
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 0.2vh solid #1f1515;
  color: #1f1515;
  font-size: 1.3vw;
}

.ManageGuest-content-holder {
  width: 100%;
}
/* .ManageGuest-content-body p {
    color: rgba(31, 21, 21, 1);
    font-size: 2vw;
    height: 8vh;
    display: flex;
    align-items: center;
    margin-left: 5vw;
  } */

.ManageGuest-table-section {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
.ManageGuest-content-table-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-dasboardAccount {
  display: none;
}

.mg-web-table-holder {
  width: 90%;
  height: 70vh;
  background-color: rgba(255, 250, 238, 1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
}
.mg-web-table {
  width: 95%;
  height: auto;
  margin: auto;
  /* border: thin solid #000; */
}
.mg-web-table .tbody {
  overflow-y: scroll;
}
.ManageGuest-thead p {
  font-size: 1.4vw;
  font-weight: 600;
  color: #1f1515;
  height: 7vh;
  display: flex;
  align-items: center;
  width: 15%;
  text-align: left;

}

.ManageGuest-scroll {
  overflow-y: auto;
  height: 55vh;
  width:100%;
}

.ManageGuest-mobile-header {
  display: none;
}
/* web section ends here */

@media (max-width: 650px) {
  .ManageGuest {
    /* height: auto; */
  }
  .ManageGuest-web {
    height: auto;
  }
 
  .ManageGuest-mobile-header {
    display: block;
  }
  .ManageGuest-guest-section {
    display: none;
  }
  .ManageGuest-content-head {
    display: none;
  }
  .ManageGuest-content-body {
    justify-content: flex-start;
    height: auto;
  }
  .ManageGuest-content-section {
    width: 100%;
    height: auto;
  }
  .ManageGuest-table-section {
    height: auto;
  }
  .ManageGuest-bar-holder {
    flex-direction: column;
    height: 15vh;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .ManageGuest-bar-holder p {
    font-size: 6vw;
    margin: 0;
  }
  .ManageGuest-bar-holder input {
    font-size: 4vw;
    margin: 2vh 0;
  }
  .mg-web-table-holder {
    width: 100%;
    height: auto;
    background-color: transparent;
    box-shadow: none;
  }
  .mg-web-table {
    width: 100%;
  }
  .ReceptionistManageGuest-thead {
    display: none;
  }
  .ManageGuest-scroll {
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }
 
}
