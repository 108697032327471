.guest-id {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.guest-img-holder {
    width: 43.3%;
    height: inherit;
    /* position: relative; */
}
.guest-img-holder img {
    width: 100%;
    height: inherit;

}
.guest-des-holder {
    width: 56.7%;
    height: inherit;
    background: #fdf0d5;
    /* position: relative; */ 
}

.guest-form {
    width: 100%;
    margin: auto;
    align-items: center;
    height: inherit;
    display: flex;
    /* gap: 20%; */
    padding-left: 25%;
}
.guest-form Link {
    
}
.guest-form  button {
    background: #1f1515;
    color: #fdf0d5;
    /* width: 15vw;
    height: 7vh; */
    padding: 1.5vh 1vw;
    border-radius: 1vw;
    margin: 0 16px;
    font-size: 1.7vw;
    font-family: Georgia;
    border: none;
}
.guest-form legend {
    color: #000;
    opacity: 0.3;
    font-family: Georgia;
    font-size: 0.9vw;
}
.guest-form fieldset {
    border: none;
}
.guest-form input {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: thin solid #1f1515;
    color: #1f1515;
    font-size: 2.2vw;
    width: 13vw;
    font-family: Georgia;
}
.guest-form input::placeholder {
    color: #1f1515;
    opacity: 0.6; 
} 

.guest-form button:hover {
    background-color: #fdf0d5;
    border: thin solid #1f1515;
    color: #1f1515;
    transition: all 0.5s;
} 

@media (max-width: 1000px) {
    .guest-form Link {
        width: 15vw;

    }
    .guest-form button{
        font-size: 14px;
        width: 20vw;
    }
    .guest-form input {
        font-size: 26px;
        width: 120px;
    }
    .guest-form legend {
        font-size: 9px;
    }
    .guest-form fieldset {
        width: 150px;
        font-size: 12px;
    }

}
@media (max-width: 650px) {
    .guest-id {
        flex-direction: column-reverse;
        height: 62vh;
    }
    .guest-img-holder {
        width: 100%;
        height: 35vh;
    }
    .guest-des-holder {
        width: 100%;
        height: 27vh;
    }
    .guest-form {
        flex-direction: column;
        
        padding: 0 0 0 0;
        gap: 20px;
        width: 100%;
        text-align: center;
    }
    .guest-form legend {
        font-size: 3.2vw;
        
    }
    .guest-form fieldset {
        display: block;
        width: 100%;
        margin-top: 20px;
    }
    .guest-form input {
        font-size: 5.5vw;
        width: 42.2vw;
        text-align: center;

    }
    /* .guest-form Link {
        width: 45%;
    } */
    .guest-form button {
        display: block;
        border: 2px solid #000;
        padding: 2vh 4vw 2vh 4vw;
        font-size: 3.9vw;
        border-radius: 0;
        background-color: #fdf0d5;;
        color: #000;
        width: 45%;
        margin: auto;
    }

    .guest-form button:hover {
        background-color: #1f1515;
        border: thin solid #fdf0d5;
        color: #fdf0d5;
        border-radius: 4vw;
        transition: all 0.5s;
      }
      
    .guest-form a {
        width: 45%; 
    } 
}

@media (max-width : 450px) {
    .guest-id {
        height: 43vh;
    }
    .guest-img-holder {
        height: 20vh;
    }
    .guest-des-holder {
        height: 23vh;
    }
    .guest-form {
        margin: 5px 0 0 0px;
    }
    .guest-form a {
        width: 50%;
    } 
}

/* @media (max-width : 450px) and (orientation : landscape) {

} */