.CashierManageGuestList-table-row {
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.2vh solid #1f1515;
  }
  .CashierManageGuestList-table-row p,
  .CashierManageGuestList-table-row div,
  .CashierManageGuestList-table-row a {
    width: 20%;
    color: #1f1515;
    font-size: 1.2vw;
  }
  .CashierManageGuestList-table-row a:hover {
    text-decoration: underline;
  }
  .CashierManageGuestListItem-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .CashierManageGuestListItem-id img {
    width: 70%;
  }
  .CashierManageGuestListItem-id-btn {
    border: none;
    background: none;
    border: 50%;
  }
  .CashierManageGuestListItem-id-btn:hover {
    width: 13%;
  }
  
  @media (max-width: 650px) {
    .CashierManageGuestList-table-row {
      height: auto;
      flex-direction: column;
      background-color: #fffaee;
      border-radius: 2vw;
      border-bottom: none;
      align-items: start;
      position: relative;
      box-shadow: 0px 0px 0.5vw 0.2vw #d2ccc3;
      padding: 1.5vh 0;
    }
    .CashierManageGuestList-table-row:hover {
      background-color: #d2ccc3;
    }
    .CashierManageGuestList-table-row a,
    .CashierManageGuestList-table-row p {
      font-size: 3.5vw;
      width: auto;
    }
    .CashierManageGuestList-table-row .CashierManageGuestListItem-position {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
      margin-bottom: 1.5vh;
    }
    .CashierManageGuestList-table-row .CashierManageGuestListItem-id {
      justify-content: flex-start;
      width: auto;
      gap: 1vw;
    }
    .CashierManageGuestList-table-row .CashierManageGuestListItem-link,
    .CashierManageGuestList-table-row .CashierManageGuestListItem-id {
      width: 45%;
      margin-left: 10%;
    }
  
    .CashierManageGuestList-table-row .CashierManageGuestListItem-link {
      font-size: 4.2vw;
      padding-bottom: 2vw;
    }
  }
  
  /* @media (max-width: 450px) { */
  /* .CashierManageGuestList-table-row a,
    .CashierManageGuestList-table-row p {
      font-size: 5vw;
    } */
  /* } */
  