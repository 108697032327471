.guestReservationHeader {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: thin solid rgba(253, 240, 213, 1);
}
.guestReservationHeader-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.guestReservationHeader-content img {
  width: 12vw;
}
.guestReservationHeader a {
  position: absolute;
  left: 5vw;
}
.guestReservationHeader-mobile-menu {
    display: none;
}

@media (max-width: 650px) {
    .guestReservationHeader {
        justify-content: space-between;
        background-color: #1F1515;
    }
    .guestReservationHeader-mobile-menu {
        display: block;
        width: 20%;
    }
    .guestReservationHeader-mobile-menu img {
        width: 10vw;
    }
    .guestReservationHeader-navigate {
        display: none;
    }
    .guestReservationHeader-content {
        width: 30%;
    }
    .guestReservationHeader-content img {
        width: 24vw;
    }
}