.CashierGuestDashboardRoomsList {
  width: 100%;
  height: 5vh;
  background-color: #ececec;
  border: 1px solid #402f2f17;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.CashierGuestDashboardRoomsList div {
  color: #1f1515;
  width: 15%;
  font-size: 1.2vw;
}
.CashierGuestDashboardRoomsList div p {
  color: #1f1515;
  font-size: 3vw;
}
.CashierGuestDashboardRoomsList
  .CashierGuestDashboardRoomsList-wide
  p:nth-child(1) {
  display: none;
}

@media (max-width: 650px) {
  .CashierGuestDashboardRoomsList {
    flex-direction: column;
    height: auto;
  }
  .CashierGuestDashboardRoomsList
    .CashierGuestDashboardRoomsList-wide
    p:nth-child(1) {
    display: block;
    width: 40%;
    text-align: right;
  }
  .CashierGuestDashboardRoomsList
  .CashierGuestDashboardRoomsList-wide
  p:nth-child(2) {
      display: block;
      width: 50%;
      text-align: left;
    }
    .CashierGuestDashboardRoomsList .CashierGuestDashboardRoomsList-wide {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0.5vh 0;
  }
  .CashierGuestDashboardRoomsList .CashierGuestDashboardRoomsList-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #A3A3A3;
    padding: 0.5vh 0;

  }
  .CashierGuestDashboardRoomsList .CashierGuestDashboardRoomsList-name p {
    width: 40%;
    text-align: right;
    color: #1F1515;
  }
}
