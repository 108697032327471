.AdminAccounts {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.AdminAccounts-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.AdminAccounts-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
}
.AdminAccounts-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.AdminAccounts-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.AdminAccounts-space-body {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.AdminAccounts-title-board {
  width: 85%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AdminAccounts-title-board h2 {
  color: #1f1515;
  font-size: 1.4vw;
}
.AdminAccounts-title-board input {
  border: none;
  font-size: 1.3vw;
  background: transparent;
  color: #1f1515;
  outline: none;
  border-bottom: 0.2vh solid #1f1515;
}

.AdminAccounts-board {
  width: 85%;
  height: 70vh;
  background-color: rgb(255, 246, 238);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.AdminAccounts-center-board {
  width: 95%;
  height: 65vh;
}
.AdminAccounts-center-board p {
  color: #1f1515;
  font-size: 1.2vw;
}
.AdminAccounts-table-head {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.AdminAccounts-table-head p {
  width: 15%;
  font-weight: bold;
}
.AdminAccounts-table-body {
  width: 100%;
  height: 50vh;
  overflow-y: scroll;
}

.AdminAccounts-table-body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.AdminAccounts-mobile-header {
  display: none;
}

.reset-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #d32f2f;
}
.filter-button {
  background-color: white;
  color: black;
  margin-right: 10px;
}
.AdminAccounts-totals {
  margin-top: 20px;
  text-align: center;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}
.ExpenseAccounts-totals {
  margin-top: 20px;
  text-align: center;
  border-bottom: 2px solid #1f1515;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.ExpenseAccounts-totals h5 {
  color: black;
}
.AdminAccounts-totals h5 {
  color: black;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
}

@media (max-width: 650px) {
  .AdminAccounts-mobile-header {
    display: block;
  }
  .AdminAccounts-board {
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 90%;
  }
  .AdminAccounts-table-head {
    display: none;
  }
  .AdminAccounts-profile {
    display: none;
  }
  .AdminAccounts-space {
    width: 100%;
  }
  .AdminAccounts-space-head {
    border: none;
    height: 7vh;
  }
  .AdminAccounts-title-board {
    height: 4vh;
  }
  .AdminAccounts-space-head p {
    font-size: 2.5vw;
  }
  .AdminAccounts-title-board h2 {
    font-size: 3vw;
  }
  .AdminAccounts-title-board input {
    font-size: 3vw;
  }
  .AdminAccounts-center-board {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .AdminAccounts-space-head p {
    font-size: 3.3vw;
  }
  .AdminAccounts-title-board h2 {
    font-size: 5vw;
  }
  .AdminAccounts-title-board input {
    font-size: 4vw;
  }
}
