.CashierExpenses {
  width: 100%;
  height: 100vh;
  background-image: url(../../../../assets/assign-room.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cashier-content-section{
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.CashierExpenses-head {
  width: 25%;
  height: inherit;
}
.CashierExpenses-hue {
  width: 100%;
  height: 100vh;
  display: flex;
  background: rgba(31, 21, 21, 0.9);
}
.CashierExpenses-body {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CashierExpenses-body p {
  font-size: 1.5vw;
}
.CashierExpenses-title p {
  font-size: 1.5vw;
}
.CashierExpenses-title select {
  font-size: 1.5vw;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 2vh 0;
}
.CashierExpenses-title select option {
  background-color: #1f1515;
}
.CashierExpenses-title {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
}
.CashierExpenses-title h3 {
  font-weight: normal;
  font-size: 1.7vw;
}
.CashierExpenses-title input {
  font-size: 4.5vw;
  width: 40%;
  text-align: center;
  font-weight: lighter;
  font-style: italic;
  opacity: 0.6;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: thick solid #fdf0d5;
}
.animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.CashierExpenses-form {
  width: 50%;
  margin: auto;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 10vh;
  /* gap: 30px;  Space between child elements */
}
.CashierExpenses-form textarea {
  background-color: transparent;
  padding: 1vh 1vw;
  outline: none;
  border: 0.1vw solid #fdf0d5;
  width: 100%;
  font-size: 1.3vw;
}
.CashierExpenses-form textarea::placeholder {
  color: #fdf0d5;
  opacity: 0.6;
  font-size: 1.3vw;
}
.CashierExpenses-form label {
  width: 100%;
  border-bottom: 0.1vw solid #fdf0d5;
  font-size: 1.3vw;
}
.CashierExpenses-label input {
  width: 80%;
  margin-left: 1vw;
  background-color: transparent;
  border: none;
  outline: none;
}
.CashierExpenses-form button {
  width: 100%;
  background-color: #fdf0d5;
  color: #1f1515;
  height: 5vh;
  border: none;
  font-size: 1.3vw;
}
.CashierExpenses-form a {
  width: 100%;
  /* background-color: #FDF0D5; */
  /* color: #1F1515; */
}

@media (max-width: 650px) {
  .CashierExpenses-form {
    width: 70%;
  }
  .CashierExpenses-head {
    display: none;
  }
  .CashierExpenses-body p {
    font-size: 4vw;
  }
  .CashierExpenses-title p,
  .CashierExpenses-title select {
    font-size: 2vw;
  }
  .CashierExpenses-form textarea {
    font-size: 3vw;
  }
  .CashierExpenses-form textarea::placeholder {
    font-size: 3vw;
  }
  .CashierExpenses-form label,
  .CashierExpenses-label input {
    font-size: 3vw;
  }
  .CashierExpenses-form button {
    font-size: 3vw;
  }
  .ReceptionAccount-mobile-header {
    display: none;
  }
}

@media (max-width: 450px) {
  .CashierExpenses-form {
    width: 85%;
  }
  .cashier-content-section {
    width: 100%;
  }
  .CashierExpenses-head {
    display: none;
  }
  .ReceptionAccount-mobile-header {
    display: block;
  }
  .CashierExpenses-body p {
    font-size: 7vw;
  }
  .CashierExpenses-title p,
  .CashierExpenses-title select {
    font-size: 3.5vw;
  }
  .CashierExpenses-form textarea {
    font-size: 5vw;
  }
  .CashierExpenses-form textarea::placeholder {
    font-size: 5vw;
  }
  .CashierExpenses-form label,
  .CashierExpenses-label input {
    font-size: 4vw;
  }
  .CashierExpenses-form button {
    font-size: 5vw;
  }
}
