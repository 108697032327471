.ReceptionistDashboard {
  background: rgba(31, 21, 21, 1);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5vh;
}
.loading-spinner {
  animation: spin 1s linear infinite;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3; /* Ensure spinner is above both image and text */
}
.image-container {
  position: relative;
  display: inline-block;
}
.text-container {
  position: relative; /* Maintain position even when image is loading */
  z-index: 1; /* Stack text below the image spinner */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.position-text {
  text-align: center;
  margin: 0;
  font-size: 1.2em; /* Adjust font size */
}
.fullname-text {
  text-align: center;
  margin: 0;
  font-size: 1em; /* Adjust font size */
}
.upload-form {
  text-align: center;
  margin: 0;
  font-size: 1.2em; /* Adjust font size */
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media (max-width: 650px) {
  .ReceptionistDashboard {
    position: absolute; /* Positioned absolutely */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999; /* High z-index to cover other content */
  }
}
.ReceptionistDashboard-upper-sec {
  width: 100%;
  height: 40vh;
}
.ReceptionistDashboard-logo {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReceptionistDashboard-logo img {
  width: 10vw;
}
.ReceptionistDashboard-profile {
  width: 100%;
  height: 30vh;
  background: rgba(64, 47, 47, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5vh 0;
}
.ReceptionistDashboard-profile h2 {
  font-size: 1.8vw;
  font-weight: normal;
}
.ReceptionistDashboard-profile p {
  font-size: 1vw;
  font-weight: normal;
}
.ReceptionistDashboard-lower-sec {
  height: 60vh;
  width: 100%;
}
.ReceptionistDashboard-lower-sec ul {
  width: 100%;
}
.ReceptionistDashboard-lower-sec ul a {
    width: 100%;
  color: rgba(255, 255, 255, 1);
  display: flex; /* Make the li a flex container */
  align-items: center;
  height: 6vh;
  list-style: none;
}
.hover-gdb-btn {
    background: rgba(64, 47, 47, 1) !important;
  }
  .ReceptionistDashboard-lower-sec ul a li {
      margin: auto;
      font-size: 1.4vw;
  }
  .ReceptionistDashboard-lower-sec ul a:hover {
      background-color: rgba(64, 47, 47, 1);
  }
  
  .gdb-profile {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2vh;
  }
  .gdb-profile img {
      width: 7vw;
      height: 7vw;
      border-radius: 50%;
      align-items: center;
  }
  
  .logout-btn {
      color: #fdf0d5;
      background-color: transparent;
      width: 100%;
      height: 6vh;
      
      margin-top: 20px;
      font-size: 1vw;
      margin: auto;
      cursor: pointer;
      border: 0;
  }
.logout-btn:hover {
    background-color: #402f2f;
}

.ReceptionistDashboard-lower-sec ul li button {
    text-align: center;
    font-size: 1.4vw;
  }
  .ReceptionistDashboard-login {
      width: 100%;
      color: #fdf0d5;
      display: flex; /* Make the li a flex container */
      align-items: center;
      justify-content: center;
      height: 6vh;
  list-style: none;
}
.ReceptionistDashboard-login:hover {
    background-color: rgba(64, 47, 47, 1);
  }
  .ReceptionistDashboard-arrow-up {
      cursor: pointer;
      padding: 25px;
      position: relative;
      display: none;
  }
  .ReceptionistDashboard-arrow-up img {
      position: absolute;
      top: 30px;
      right: 30px;
      align-items: center;
  }
  .ReceptionistDashboard-profile img {
      width: 7vw;
      height: 7vw;
      border-radius: 50%;
      align-items: center;
  }
  .hide {
      display: none;
  }
  
  
  @media (max-width: 650px) {
      .ReceptionistDashboard-arrow-up {
          display: block;
      }
      .ReceptionistDashboard-profile {
          height: auto;
          margin: 0;
          padding: 3vh 0;
      }
      .ReceptionistDashboard-logo img {
          width: 30vw;
      }
      .ReceptionistDashboard-profile img {
          width: 20vw;
          height: 20vw;
      }
      .ReceptionistDashboard-profile h2 {
          font-size: 4vw;
  }
  .ReceptionistDashboard-profile p {
      font-size: 2vw;
  }
  .ReceptionistDashboard-lower-sec ul a li {
      font-size: 3vw;
  }
  .ReceptionistDashboard-lower-sec ul {
      margin-top: 10vh;
  }
  .ReceptionistDashboard-lower-sec .ReceptionistDashboard-login {
    font-size: 3vw;
  }
}

@media (max-width: 400px) {
  .ReceptionistDashboard-logo img {
      width: 50vw;
  }
  .ReceptionistDashboard-lower-sec ul {
      margin-top: 0vh;
  }
  .ReceptionistDashboard-profile h2 {
      font-size: 6vw;
  }
  .ReceptionistDashboard-profile p {
      font-size: 3.5vw;
  }
  .ReceptionistDashboard-lower-sec ul a li {
      font-size: 5vw;
  }

}