.OnlineBookingList {
    width: 100%;
    height: 100vh;
    background-color: rgba(253, 240, 213, 1);
  }
  
  .OnlineBookingList-web {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: rgba(253, 240, 213, 1);
  }
  .OnlineBookingList-guest-section {
    width: 25%;
    height: inherit;
  }
  .OnlineBookingList-content-section {
    width: 75%;
    height: inherit;
    color: rgba(31, 21, 21, 1);
  }
  .OnlineBookingList-content-head {
    width: 100%;
    height: 10vh;
    border-bottom: 0.2vh solid #1f1515;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .OnlineBookingList-content-head a {
    color: rgba(31, 21, 21, 1);
    margin-right: 7vh;
    font-size: 1.05vw;
  }
  .OnlineBookingList-content-body {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .OnlineBookingList-bar-holder {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-items: center;
  }
  .OnlineBookingList-bar-holder p  {
      color: #1f1515;
      margin-left: 5vw;
      font-size: 2vw;
  }
  .OnlineBookingList-bar-holder input  {
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 0.2vh solid #1f1515;
      color: #1f1515;
      font-size: 1.3vw;
  }
  
  .OnlineBookingList-content-holder {
      width: 100%;
  }
  /* .OnlineBookingList-content-body p {
      color: rgba(31, 21, 21, 1);
      font-size: 2vw;
      height: 8vh;
      display: flex;
      align-items: center;
      margin-left: 5vw;
    } */
  
  .OnlineBookingList-table-section {
    display: flex;
    width: 100%;
    height: 80Vh;
    justify-content: center;
    align-items: center;
  }
  .OnlineBookingList-content-table-section {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-dasboardAccount {
    display: none;
  }
  
  .OnlineBookingList-web-table-holder {
    width: 90%;
    height: 70vh;
    background-color: rgba(255, 250, 238, 1);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  }
  .OnlineBookingList-web-table {
    width: 95%;
    height: auto;
    margin: auto;
    /* border: thin solid #000; */
  }
  .OnlineBookingList-web-table .tbody {
    overflow-y: scroll;
  }
  
  .OnlineBookingList-thead {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    color: #000;
  
    border-bottom: 0.2vh solid #000;
  }
  
  .OnlineBookingList-thead p {
    font-size: 1.3vw;
    font-weight: 600;
    color: #1f1515;
    height: 7vh;
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    /* web section ends here */
  }
  .OnlineBookingList-display-plate {
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
  }
  .OnlineBookingList-mobile-header {
    display: none;
  }
  

  @media (max-width: 650px) {
    .OnlineBookingList-mobile-header {
      display: block;
    }
    .OnlineBookingList-guest-section {
      display: none;
    }
    .OnlineBookingList-content-section {
      width: 100vw;
    }
    .OnlineBookingList-content-head {
      display: none;
    }
    .OnlineBookingList-bar-holder {
      height: 10vh;
    }
    .OnlineBookingList-bar-holder p {
      margin: 0;
      font-size: 5vw;
    }
    .OnlineBookingList-web {
      height: auto;
    }
    .OnlineBookingList-content-body {
      height: auto;
    }
    .OnlineBookingList-table-section {
      height: auto;
    }
    .OnlineBookingList-web-table-holder {
      height: auto;
      width: 100%;
      box-shadow: none;
      background-color: transparent;
    }
    .OnlineBookingList-web-table {
      width: 100%;
    }
    .OnlineBookingList-thead {
      display: none;
    }
    .OnlineBookingList-display-plate {
      height: 77vh;
      
      /* box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25); */
    }
    
  }