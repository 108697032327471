/** @format */

.GuestInfoTableRow {
  border-bottom: 0.2vh solid #000;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 7vh;
  border: none;
}
.GuestInfoTableRow:hover {
  /* background: rgba(31, 21, 21, 0.2); */
}
.GuestInfoTableRow p {
  color: #1f1515;
  text-align: center;
  width: 15%;
  /* font-size: 1.05vw; */
  font-size: 1.4vw;
}
.GuestInfoTableRow-name-link, .GuestInfoTableRow-mobile-number {
  width: 15%;
  display: flex;
  justify-content: flex-start;
  font-size: 1.4vw;
}
.GuestInfoTableRow .GuestInfoTableRow-name {
  text-align: left;
  width: auto;
}
.GuestInfoTableRow .GuestInfoTableRow-name a {
  text-align: left;
  color: #1f1515;
  font-size: 1.05vw;
}
.GuestInfoTableRow-name:hover {
  text-decoration: underline;
}
.GuestInfoTableRow div {
  color: #1f1515;
  text-align: center;
  width: 12%;
  font-size: 1.05vw;
}

.GuestInfoTableRow-id-sec {
  display: flex;
  flex-direction: row;
  justify-content: space;
  align-items: center;
  gap: 1vw;
  width: 15%;
}
.GuestInfoTableRow-id-sec p {
  width: 50%;
}
.GuestInfoTableRow-id-sec span img {
  width: 1.4vw;
}
.copy-btn {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-color: none;
}
.GuestInfoTableRow-mobile {
  display: none;
}
.GuestInfoTableRow-phone:hover {
  text-decoration: underline;
}
/* .GuestInfoTableRow span{
    display: flex;
    align-items: center;
    justify-content: center;
} */
/* .GuestInfoTableRow button {
    color: rgba(255, 250, 238, 1);
    background-color: rgba(31, 21, 21, 1);
    border-radius: 3vw;
    align-self: center;
    border: thin solid transparent;
    width: 40%;
    font-size: 1vw;
    font-weight: bold;
    padding-top: 0.2vh;
    padding-bottom: 0.2vh;
} */
@media (max-width: 650px) {
  /* .GuestInfoTableRow {
    display: none;
  } */
  .GuestInfoTableRow {
    /* display: none; */
    border-bottom: none;
    flex-direction: column;
    position: relative;
    height: auto;
    /* justify-content: flex-start; */
    justify-content: space-evenly;
    align-items: start;
    padding: 0vh 5vw;
    border: none;
    background-color: #FFFAEE;
    margin-top: 2vh;
    box-shadow: 0px 0px 4px 2px #00000040;
    border-radius: 3vw;
    height: 12vh;
    width: 90%;
  }


  .GuestInfoTableRow-name-link {
    width: auto;
  }

  .GuestInfoTableRow:hover {
    background-color: #D2CCC3 ;
  }
  .GuestInfoTableRow * {
    color: #1F1515;
  }
  .GuestInfoTableRow p {
    font-size: 3vw;
    width: auto;
  }
  .GuestInfoTableRow-id-sec button {
    width: 5vw;
  }
  .GuestInfoTableRow-id-sec button img {
    width: 3vw;
  }  
  .GuestInfoTableRow-phone {
    position: absolute;
    right: 0;
    margin: 0 17vw 0 0;
    bottom: 0;
    margin-bottom: 2vh;
  }
.GuestInfoTableRow-id-sec {
  position: absolute;
  bottom: 0;
  margin-bottom: 2vh;
}
.copy-success {
  font-size: 4vw;
}







 
  .GuestInfoTableRow-mobile-panel {
    display: flex;
    /* justify-content: ; */
    gap: 10%;
    align-items: center;
    width: 100%;
  }
  .GuestInfoTableRow-mobile-unique-id {
    display: flex;
    gap: 2vw;
  }
  .GuestInfoTableRow-mobile-unique-id button {
    border: none;
    background-color: transparent;
  }
}