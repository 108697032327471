.OpenSales {
  background-image: url(../../../../assets/assign-room.png);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.OpenSales-hue {
  width: 100%;
  height: 100vh;
  background: rgba(31, 21, 21, 0.9);
}
.OpenSales-head {
    height: 10vh;
    width: 100%;
    border-bottom: thin solid ;
}
.OpenSales-body {
    width: 50%;
    margin: auto;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
     
}
.OpenSales-header-details {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    align-items: center;
    margin-top: 10vh;
}
.OpenSales-header-details .OpenSales-title {
    font-size: 1.3vw;
}
.OpenSales-header-details h1 {
    text-decoration: underline;
    font-style: italic;
    font-weight: 500;
    font-size: 4.5vw;
}
.OpenSales-header-details .OpenSales-staff {
    font-size: 2vw;

}

.OpenSales-header-details select {
    color: #FDF0D5;
    background: transparent;
    border: none;
    border: none;
    outline: none;
    font-size: 1.25vw;
    align-self: self-start;  
}
.OpenSales-header-details #paymentCategory option {
    color: #FDF0D5;
    background: #1F1515;
}
.OpenSales-header-details #paymentCategory option:hover {
    color: #1F1515;
    background: #FDF0D5;
}
.OpenSales-form {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    margin-top: 5vh;
    width: 70%;
}

.OpenSales-form textarea {
    background-color: transparent;
    border: thin solid #FDF0D5;
    outline: none;
    width: 100%;
    height: 13vh;
    padding: 1vh 1vw;
}
.OpenSales-form textarea::placeholder {
    color: #FDF0D5;
    /* padding: 10px 0 0 10px; */
    font-style: italic;
    opacity: .6;
    font-size: 1.2vw;
}

.OpenSales-form label {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 1.2vw;
    border-bottom: 0.2vh solid #FDF0D5;
}

.OpenSales-form input {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    /* border-bottom: thin solid #FDF0D5; */
    height: 3vh;
}
.OpenSales-form input::placeholder {
    color: #FDF0D5;
    font-size: 1.2vw;
}

.OpenSales-form button {
    background-color: #FDF0D5;
    color: #1F1515;
    border: none;
    padding: 1vh 0.2vw;
    font-size: 1.1vw;
    width: 100%;
}

@media (max-width : 650px) {
    .OpenSales-body {
        width: 85%;
    }
    .OpenSales-header-details .OpenSales-title {
        font-size: 4.4vw;
    }
    .OpenSales-header-details .OpenSales-staff {
        font-size: 3vw;
    }
    .OpenSales-header-details select  {
        font-size: 3vw;
    }
    .OpenSales-form textarea::placeholder {
        font-size: 3vw;
    }
    .OpenSales-form label {
        font-size: 3vw;
    }
    .OpenSales-form button {
        font-size: 3vw;
    }
}

@media (max-width: 450px) {
    .OpenSales-header-details {
        width: 100%;
    }
    .OpenSales-form  {
        width: 100%;
    }
    .OpenSales-header-details .OpenSales-title {
        font-size: 7vw;
    }
    .OpenSales-header-details .OpenSales-staff {
        font-size: 5vw;
    }
    .OpenSales-header-details select {
        font-size: 5vw;
    }
    .OpenSales-form textarea::placeholder {
        font-size: 5vw;
    }
    .OpenSales-form label {
        font-size: 5vw;
    }
    .OpenSales-form button {
        font-size: 5vw;
        margin-top: 10vh;
    }
}
