.BillTo {
  width: 100%;
  height: 100vh;
  background-image: url(../../../../assets/assign-room.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.BillTo-hue {
  width: 100%;
  height: 100vh;
  background: rgba(31, 21, 21, 0.9);
}
.BillTo-body {
  width: 100%;
  margin: auto;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.BillTo-body p {
  font-size: 1.5vw;
}

.BillTo-title p {
  font-size: 1.5vw;
}

.BillTo-guestId {
  background-color: transparent;
  border: none;
  color: #fdf0d5;
  font-size: 4vw;
  border-bottom: 0.5vh solid #fdf0d5;
  width: 45%;
  text-align: center;
  opacity: 0.5;
  outline: none;
}
.BillTo-guestId::placeholder {
  color: #fdf0d5;
}
.BillTo-title select {
  font-size: 1.5vw;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 2vh 0;
}
.BillTo-title select option {
  background-color: #1f1515;
}
.BillTo-form {
  width: 40%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.BillTo-form textarea {
  background-color: transparent;
  padding: 1vh 1vw;
  outline: none;
  border: 0.1vw solid #fdf0d5;
  width: 100%;
  font-size: 1.3vw;
}
.BillTo-form textarea::placeholder {
  color: #fdf0d5;
  opacity: 0.6;
  font-size: 1.3vw;
}
.BillTo-form label {
  width: 100%;
  border-bottom: 0.1vw solid #fdf0d5;
  font-size: 1.3vw;
}
.BillTo-label input {
  width: 80%;
  margin-left: 1vw;
  background-color: transparent;
  border: none;
  outline: none;
}

.BillTo-form button {
  width: 100%;
  background-color: #fdf0d5;
  color: #1f1515;
  height: 5vh;
  border: none;
  font-size: 1.3vw;
}
.BillTo-form a {
  width: 100%;
  /* background-color: #FDF0D5; */
  /* color: #1F1515; */
}
.BillTo-inputId {
  display: flex;
  justify-content: center;
}
.BillTo-inputId label {
  display: none;
  border: transparent;
  width: auto;
  
}



@media (max-width: 650px) {
  .BillTo-form {
    width: 70%;
  }
  .BillTo-body p {
    font-size: 4vw;
  }
  .BillTo-guestId {
    font-size: 7vw;
  }
  .BillTo-title select {
    font-size: 3vw;
  }
  .BillTo-form textarea {
    font-size: 3vw;
  }
  .BillTo-form textarea::placeholder {
    font-size: 3vw;
  }
  .BillTo-form label {
    font-size: 3vw;
  }
  .BillTo-form button  {
    font-size: 3vw;
  }
}

@media (max-width: 450px) {
  .BillTo-form {
    width: 85%;
  }
  .BillTo-inputId {
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.2vh solid #fdf0d5;
    width: 100%;
  }
  .BillTo-inputId label {
    display: block;
    font-size: 5vw;
  }
  .BillTo-inputId input {
    border-bottom: none;
    font-size: 4vw;
    flex-grow: 1;
    text-align: left;
    padding: 0 1vw;
  }
  .BillTo-title {
    align-self: flex-start;
  }
  .BillTo-form textarea {
    font-size: 4vw;
  }
  .BillTo-form textarea::placeholder {
    font-size: 4vw;
  }
  .BillTo-form label {
    font-size: 4vw;
  }
  .BillTo-form button {
    font-size: 4vw;
  }
  .BillTo-body p {
    font-size: 5vw;
  }
}