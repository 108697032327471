.mobile-nav-bar {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw; /* Ensures it does not overflow the viewport */
    height: 100%;
    z-index: 100;
    background-color: rgba(31, 21, 21, 0.92);
    transform: translateX(-100%); /* Start hidden off-screen */
    transition: transform 0.3s ease-in-out; /* Smooth show/hide animation */
}

.mobile-nav-bar.show {
    transform: translateX(0); /* Slide into view when active */
}

.mobile-nav-bar.hide {
    transform: translateX(-100%); /* Slide out of view */
}

@media (max-width: 650px) {
    .mnb-logo {
        display: flex;
        flex-direction: column; /* Stack logo and text vertically */
        align-items: center; /* Center align items horizontally */
        justify-content: center; /* Center align items vertically */
        width: 100%; /* Take full width of the container */
        padding: 20px; /* Add some spacing */
    }
    .mnb-logo img.mob-nav-logo {
        width: 30%; /* Adjust the logo size as needed */
        margin-bottom: 10px; /* Add space between logo and text */
    }
    .mnb-logo img.mob-nav-two {
        width: 50%; /* Adjust the text size as needed */
    }

    .cont-mob-navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto; /* Prevent vertical overflow */
    }

    .mnb-arrow-up {
        padding: 25px;
        width: 100%;
        text-align: right;
    }

    .mnb-arrow-up img {
        cursor: pointer;
    }

    .mnb-nav-list {
        width: 100%;
        height: auto;
        text-align: center;
    }

    .mnb-nav-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mnb-nav-list ul li {
        padding: 15px 10px;
        cursor: pointer;
    }
}

