.CashierAccount {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.CashierAccount-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.CashierAccount-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
}
.CashierAccount-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ExpenseAccounts-totals h5 {
  color: black;
}
.AdminAccounts-totals h5 {
  color: black;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
}
.CashierAccount-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.CashierAccount-space-body {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.CashierAccount-title-board {
  width: 85%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CashierAccount-title-board p {
  color: #1f1515;
  font-size: 2vw;
}
.CashierAccount-title-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}
.CashierAccount-title-select select {
  background-color: transparent;
  color: #1f1515;
  padding: 1vh 1vw;
  border-radius: 2vw;
  outline: none;
  font-size: 1.3vw;
  margin-left: 1vw;
}

.CashierAccount-title-select select option {
  background-color: #1f1515;
}

.CashierAccount-board {
  width: 85%;
  height: 70vh;
  background-color: rgb(255, 246, 238);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.CashierAccount-center-board {
  width: 90%;
  height: 65vh;
}
.CashierAccount-center-board p {
  color: #1f1515;
  font-size: 1.2vw;
}
.CashierAccount-table-head {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.CashierAccount-table-head p {
  width: 20%;
  font-weight: bold;
}
.CashierAccount-table-body {
  width: 100%;
  height: 57vh;
  overflow-y: scroll;
}
.CashierAccount-table-body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.CashierAccount-table-row {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.CashierAccount-table-row p {
  width: 20%;
}

.CashierAccount-mobile-header {
  display: none;
}

@media (max-width: 650px) {
  .CashierAccount-mobile-header {
    display: block;
  }
  .CashierAccount-profile {
    display: none;
  }
  .CashierAccount-space {
    width: 100%;
    height: auto;
  }
  .CashierAccount-space-head {
    height: auto;
    border-bottom: none;
    padding: 1vh 0;
  }
  .CashierAccount-space-head p {
    font-size: 3vw;
  }
  .CashierAccount-space-body {
    height: auto;
  }
  .CashierAccount-board {
    height: auto;
    box-shadow: none;
    background-color: #fdf0d5;
    width: 100%;
  }
  .CashierAccount-center-board {
    height: auto;
  }
  .CashierAccount-table-body {
    /* adjust table from here */
    height: 60vh;
  }
  .CashierAccount-table-head {
    display: none;
  }
  .CashierAccount-title-select select {
    background-color: #d9d9d9;
    font-size: 3vw;
  }
  .CashierAccount-title-board p {
    font-size: 4vw;
  }
  /* adjust table from here 2*/
  .CashierAccount-table-foot {
    height: 10vh;
    border-bottom: transparent !important;

    background-color: #ececec;
  }
  .CashierAccount-table-foot-center {
    justify-content: flex-start;
    gap: 10%;
  }
  .CashierAccount-table-foot-center h5 {
    font-size: 4vw;
    color: #1f1515;
  }
  .CashierAccount-table-foot-center h2:nth-child(2) {
    color: green;
  }
}

@media (max-width: 400px) {
  .CashierAccount-space-head p {
    font-size: 5vw;
  }
  .CashierAccount-title-board p {
    font-size: 5vw;
  }
  .CashierAccount-title-select select {
    padding: 0.5vh 0.5vw;
    /* font-size: 3vw; */
  }
}
