.dashAccount-web-table-holder {
    width: 90%;
    height: 70vh;
    background-color: rgba(255, 250, 238, 1);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) ;
}
.dashAccount-web-table {
    width: 95%;
    height: 68vh;
    margin: auto;
    
}
.dashAccount-web-table div {
    border-bottom: thin solid #000 !important; 
    height: 7vh;
}
.dashAccount-web-table thead div  {
    color: #1F1515;
    
    font-size: 1.05vw;
    display: flex;
    justify-content: space-around;
}
.dashAccount-web-table thead div span  {
    color: #1F1515;
    text-align: center;
    font-weight: 600;
    font-size: 1.05vw;
    width: 17%;
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-bottom: 1vh;
}
.dashAccount-web-table section {
    overflow-y: scroll;
    height: 45vh !important;
}
.dashAccount-web-table section div {
    display: flex;
    justify-content: space-around;
}
.dashAccount-web-table tfoot div {
    display: flex;
    justify-content: space-around;
}
.dashAccount-web-table section div span  {
    color: #1F1515;
    text-align: center;
    
    font-size: 1.05vw;
    width: 17%;
    display: flex;
    align-items: end;
    margin-bottom: 1vh;
}
.dashAccount-web-table tfoot div span  {
    color: #1F1515;
    text-align: center;
    
    font-size: 1.05vw;
    width: 17%;
    display: flex;
    align-items: end;
    margin-bottom: 1vh;
}

.dashAccount-total {
    color: #000;
    float: right;
    font-size: 1.2vw;
}
.dashAccount-total-amount {
    color: #000;
    font-size: 1.05vw;
    font-weight: 600;
}

.bill-red {
    color: rgba(224, 0, 40, 1) !important; 
}
.paid-green {
    color: rgba(0, 144, 32, 1) !important;
    
}