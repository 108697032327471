.StaffList-table-row {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.StaffList-table-row p,
.StaffList-table-row div,
.StaffList-table-row a {
  width: 20%;
  color: #1f1515;
  font-size: 1.2vw;
}
.StaffList-table-row a:hover {
  text-decoration: underline;
}
.StaffListItem-id {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.StaffListItem-id img {
  width: 70%;
}
.StaffListItem-id-btn {
  border: none;
  background: none;
  border: 50%;
}
.StaffListItem-id-btn:hover {
  width: 13%;
}

@media (max-width: 650px) {
  .StaffList-table-row {
    height: auto;
    flex-direction: column;
    background-color: #fffaee;
    border-radius: 2vw;
    border-bottom: none;
    align-items: start;
    position: relative;
    box-shadow: 0px 0px 0.5vw 0.2vw #d2ccc3;
    padding: 1.5vh 0;
  }
  .StaffList-table-row:hover {
    background-color: #d2ccc3;
  }
  .StaffList-table-row a,
  .StaffList-table-row p {
    font-size: 3.5vw;
    width: auto;
  }
  .StaffList-table-row .StaffListItem-position {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    margin-bottom: 1.5vh;
  }
  .StaffList-table-row .StaffListItem-id {
    justify-content: flex-start;
    width: auto;
    gap: 1vw;
  }
  .StaffList-table-row .StaffListItem-link,
  .StaffList-table-row .StaffListItem-id {
    width: 45%;
    margin-left: 10%;
  }

  .StaffList-table-row .StaffListItem-link {
    font-size: 4.2vw;
    padding-bottom: 2vw;
  }
}

/* @media (max-width: 450px) { */
/* .StaffList-table-row a,
  .StaffList-table-row p {
    font-size: 5vw;
  } */
/* } */
