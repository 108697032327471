.staff-content-table-section {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.spinner {
  animation: spin 1s linear infinite;
  font-size: 1.2em; /* Adjust size as needed */
  position: absolute; /* Keeps the spinner in place without shifting content */
  color: inherit; /* Matches spinner color with button text color */
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.input-field {
  color: black; /* Set font color to black */
}
.rooms-table th, .rooms-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rooms-table th {
  background-color: #f2f2f2;
}
.add-room-form-container {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-input label {
  margin-bottom: 8px;
  font-weight: 600;
  color: black;
}
.form-input input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;
}
.form-input input:focus {
  border-color: #007bff;
}
.form-submit-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.submit-button:hover {
  background-color: #0056b3;
}
.submit-button.loading {
  cursor: not-allowed;
  opacity: 0.7;
}
.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.add-room-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; /* Limits input width for larger screens */
}
.rooms-table-container {
  max-height: 400px; /* Set a max height to constrain the table's container */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */
  border: 1px solid #ddd; /* Add a border for better visuals */
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9; /* Light background to distinguish the table area */
}
.rooms-table {
  width: 100%;
  border-collapse: collapse; /* Remove gaps between table borders */
  font-family: Arial, sans-serif; /* Set a clean font style */
  font-size: 14px; /* Adjust the font size */
}
.delete-room-button:hover {
  background-color: #e63946; /* Slightly darker red on hover */
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: background-color 0.3s ease;
}
.add-room-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff; /* Blue button for Add Room */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s;
}
.add-room-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
.rooms-table thead {
  position: sticky; /* Keep the header visible when scrolling */
  top: 0;
  background-color: #007bff; /* Add a header background color */
  color: white; /* White text for contrast */
}
.rooms-table thead tr th {
  font-weight: bold;
  font-size: 20px;
  color: black; /* White text for contrast */
}
.rooms-table tbody tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}
.rooms-table th,
.rooms-table th,
.rooms-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Subtle border for table rows */
}
.rooms-table tbody tr:last-child td {
  border-bottom: none; /* Remove border for the last row */
}
.rooms-table td {
  color: black; /* Set table fonts to black */
}
.rooms-table th {
  background-color: #f4f4f4;
}
.rooms-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.rooms-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
.StaffDashboard-rooms-list {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}
.room-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.delete-room-button {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: background-color 0.3s ease;
}
.disabled-room-button {
  color: #aaa;
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: not-allowed;
  transition: background-color 0.3s;
}
.StaffDashboard {
  width: 100%;
  height: 100vh;
  position: relative; 
  background-color: rgba(253, 240, 213, 1);
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}
.spinner {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error-message {
  color: red;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}
.StaffDashboard-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.StaffDashboard-guest-section {
  width: 25%;
  height: inherit;
}
.StaffDashboard-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.StaffDashboard-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.StaffDashboard-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.StaffDashboard-content-body {
  margin-left: 3rem;
  width: 90%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds space between the form and table */
}
.StaffDashboard-content-body p {
  margin: 0;
  width: auto;
}
.StaffDashboard-content-body p {
  color: rgba(31, 21, 21, 1);
  font-size: 2vw;
  height: 8vh;
  display: flex;
  align-items: center;
  margin-left: 5vw;
}

.staff-dashboard-button {
  border: none;
  padding: 2vh 3vw;
  background-color: rgba(64, 47, 47, 1);
  color: rgba(253, 240, 213, 1);
  border-radius: 1vw;
  font-size: 1.5vw;
  margin: 0 1rem;
}
.staff-dashboard-button-bookings {
  border: none;
  padding: 2vh 3vw;
  background-color: rgba(64, 47, 47, 1);
  color: rgba(253, 240, 213, 1);
  border-radius: 1vw;
  font-size: 1.5vw;
  margin: 0 1rem;
  position: relative;
  display: inline-block;
}
.staff-room-btn-section {
  width: 100%;
  height: 13vh;
  overflow-y: scroll;
}
.hover-gdb-btn {
  background: rgba(64, 47, 47, 1) !important;
}
.staff-apartment-btn-section-reserved {
  overflow-y: scroll;
  height: 13vh;
}
.staff-apartments {
  width: 100%;
  height: auto;
  padding: 7vh 0 0 0;
}
.staff-apartment-btn-section {
  overflow-y: scroll;
  height: 13vh;
}

.staff-option-btn-section {
  border-top: 0.2rem solid rgba(64, 47, 47, 1);
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
}
/* button notification */

.Bookings-notification {
  width: 100%;

  padding: 4vh 0 0 0;
}
.button-StaffDashboard {
  background-color: rgba(64, 47, 47, 1);
  border: none;
  color: #fdf0d5;
  width: 20%;
  padding: 2vh 3vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5vw;
  border-radius: 1vw; /* Make the button circular */
  margin: 0 1rem;
}

.button-text {
  position: relative; /* Position the notification */
}

.notification {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(64, 47, 47, 1);
  color: white;
  border: 0.1vw solid #fdf0d5;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.StaffDashboard-mobile-header {
  display: none;
}

@media (max-width: 650px) {
  .StaffDashboard-guest-section {
    display: none;
  }
  .StaffDashboard-content-head {
    display: none;
  }
  .StaffDashboard-mobile-header {
    display: block;
  }
  .StaffDashboard-content-section {
    width: 100%;
  }
  .StaffDashboard-content-body {
    margin-left: 7%;
    height: 110vh;
  }
  .StaffDashboard {
    height: auto;
  }
  .StaffDashboard-web {
    height: auto;
  }
  .StaffDashboard-content-body p {
    font-size: 5vw;
    margin: 0;
  }
  .staff-content-table-section {
    height: 100vh;
  }
  .staff-room-btn-section {
    height: 40vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    
  }
  .staff-room-btn-section::-webkit-scrollbar {
    width: 0.2rem;
    background-color: #fdf0d5;
    border-radius: 1rem;
    
  }
  .staff-room-btn-section::-webkit-scrollbar-thumb {
    width: 0.2rem;
    background-color: #1f1515;
    border-radius: 1rem;
    
  }
  .staff-apartments {
    padding: 0;
  }
  .add-room-form {
    padding: 10px;
  }
  .form-input input {
    font-size: 14px;
  }
  .button-StaffDashboard {
    padding: 1vh 5vw 1vh 1vw;
    border-radius: 1vw;
} 
.button-StaffDashboard .button-text {
    color: #fdf0d5;
    font-weight: normal;
    padding: 1vh 6vw 1vh 1vw;
  }
  .staff-option-btn-section {
    flex-direction: row; /* Ensure horizontal layout */
    justify-content: space-between; /* Adjust spacing between buttons */
    padding: 1rem; /* Reduce padding for smaller screens */
    height: auto; /* Remove fixed height for flexibility */
  }
  .staff-option-btn-section a {
    font-size: 3.5vw;
    padding: 2vh 5vw;
    border-radius: 4vw;
  }
  .button-StaffDashboard {
    width: 40%;
    font-size: 4vw;
  }
  .rooms-table {
    font-size: 14px;
  }
  .add-room-button {
    width: auto;
    margin: 20px auto;
  }
}
