.GuestAccessPage-web {
  
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.GuestAccessPage-guest-section {
  width: 25%;
  height: inherit;
}
.GuestAccessPage-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.GuestAccessPage-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.GuestAccessPage-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.GuestAccessPage-content-body {
  width: 100%;
  height: 90vh;
}
.GuestAccessPage-content-body p {
  color: rgba(31, 21, 21, 1);
  font-size: 2vw;
  height: 8vh;
  display: flex;
  align-items: center;
  margin-left: 5vw;
}
.GuestAccessPage-content-table-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.access-card {
  width: 90%;
  height: 70vh;
  background-color: rgba(255, 250, 238, 1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
.access-card-box {
  width: 80%;
  color: #000000;
}
.access-card-box p {
  font-size: 1.2vw;
  margin: 0;
  height: 4vh;
}
.access-card-box h2 {
    /* font-size: 1.4rem; */
    font-size: 2vw;
    margin: 0;
    color: #000000;
}
.double-box {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    /* padding: 5vh 0 5vh 0; */
    height: 10vh;
    gap: 20vw;
}

.double-box p {
    font-size: 0.9vw;
    margin: 0;
    height: 4vh;
}

.double-box h2 {
    font-size: 1vw;
}

.access-contact-box p {
    font-size: 0.9vw;
}
.access-contact-box h2 {
    font-size: 1vw;
}
.GuestAccessPage-mobile-header {
  display: none;
}
/* Desktop styling end here */

/* Mobile styling end here */
@media (max-width: 650px) {
  .GuestAccessPage-guest-section {
    display: none;
  }
  .GuestAccessPage-content-section {
    width: 100%;
  }
  .GuestAccessPage-content-head {
    display: none;
  }
  .GuestAccessPage-mobile-header {
    display: block;
  }
  .access-card {
    justify-content: flex-start;
    gap: 4vh;
  }
  .access-card-box {
    height: 10vh;
  }
  .double-box {
    flex-direction: column;
    align-items: flex-start;
    height: 25vh;
    margin-bottom: 10vh;
    gap: 3vh;
  }
  .double-box div div p {
    height: 4vh;
  }
  .access-card-box p {
    font-size: 2vw;
  }
  .access-card-box h2 {
    font-size: 3vw;
  }
}

@media (max-width: 350px) {
  .access-card-box p {
    font-size: 4vw;
  }
  .access-card-box h2 {
    font-size: 5vw;
  }
  
}
