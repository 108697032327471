.AdminAccounts-table-row {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.AdminAccounts-table-row div {
  width: 15%;
}
.AdminAccounts-table-row div p:first-child {
  display: none;
}
.AdminAccounts-table-row p {
  color: #1f1515;
}

@media (max-width: 650px) {
  .AdminAccounts-table-row {
    flex-direction: column;
    height: auto;
    position: relative;
    background-color: #fffaee;
    border: 1px solid #00000017;
  }
  .AdminAccounts-table-row div p:first-child {
    display: block;
  }
  .AdminAccounts-table-row div:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
  }
  .AdminAccounts-table-row div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
  }
  .AdminAccounts-table-body {
    display: flex;
    flex-direction: column;
    gap: 0.7vh;
    height: 70vh;
  }
  .AdminAccounts-table-row div {
    display: flex;
  }
  .AdminAccounts-table-row div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .AdminAccounts-table-row p {
    font-size: 3vw;
    width: 40%;
    padding: 0.71vh 0;
  }
  .AdminAccounts-table-row p:first-child {
    text-align: right;
  }
  .AdminAccountList-Bill {
    margin-top: 4vh;
  }
  .AdminAccounts-table-row .AdminAccountList-staffName p:nth-child(1),
  .AdminAccounts-table-row .AdminAccountList-Category p:nth-child(1) {
    display: none;
  }
  .AdminAccountList-staffName {
    flex-direction: row-reverse;
  }

  .AdminAccountList-Category p {
    text-align: right;
  }
  .AdminAccountList-Bill {
    border-top: 0.35vh solid #00000017;
  }
}

@media (max-width: 450px) {
  .AdminAccounts-table-row p {
    font-size: 4.4vw;
  }
}
