.BartenderDashboard {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  position: relative; 
}
.BartenderDashboard-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.BartenderDashboard-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.BartenderDashboard-space {
  width: 75%;
  height: inherit;
  background-color: #fdf0d5;
}
.BartenderDashboard-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.BartenderDashboard-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.BartenderDashboard-space-body {
  margin-left: 3rem;
  height: 75vh;
  width: 100%;
 /* display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around; 
  border-bottom: 0.3vh solid #1f1515;*/
}
.BartenderDashboard-board-up {
  width: 100%;
  height: 65vh;
}
.BartenderDashboard-board-up p {
  color: #1f1515;
  font-size: 1.8vw;
  margin: 2vh 0;
  margin-left: 5vw;
}
.BartenderDashboard-board-down {
  width: 100%;
  height: 35vh;
  display: flex;
  align-items: center;
}
.BartenderDashboard-board-up-container {
  margin-left: 5vw;
  height: 27vh;
  width: 90%;
}
.BartenderDashboard-board-up-container button {
  font-size: 1.3vw;
  padding: 1vh 2vw;
  color: #fdf0d5;
  border: none;
  background-color: #1f1515;
  border-radius: 0.5vw;
  margin-right: 1.5vw;
  margin-top: 1vh;
}
.BartenderDashboard-mobile-header {
  display: none;
}



@media (max-width: 650px) {
  .BartenderDashboard-mobile-header {
    display: block;
  }
  .BartenderDashboard {
    height: auto;
  }
  .BartenderDashboard-web {
    height: auto;
  }
  .BartenderDashboard-profile {
    display: none;
  }
  .BartenderDashboard-space-head {
    display: none;
  }
  .BartenderDashboard-space {
    width: 100%;
  }
  .BartenderDashboard-space-body {
    border: transparent;
    height: 110vh;
    margin-left: 7%;
  }
  .BartenderDashboard-board-up p{
    font-size: 4vw;
  }
  .BartenderDashboard-board-up-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .BartenderDashboard-board-up-container button  {
    font-size: 4vw;
    margin: 3vh 0 0 2vw;
    padding: 2vh 6vw;
    border-radius: 3vw;
  }
}