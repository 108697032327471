
* {
  box-sizing: border-box;
}
.main-contact {
    width: 100%;
    height: 45vh;
    background-color: #fdf0d5;
    justify-content: center;
    align-items: center;
    display: flex; /* Make it a flex container */
    flex-direction: column; /* Stack children vertically by default */
    padding: 20px;
    margin: 0 auto; /* Center on the page */
    overflow: hidden;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  /* margin-top: 5px;*/
  margin-top: -40px; /* Remove top margin to touch the .logo */
  padding-top: 0; 
}
.contact {
    width: 100%;
    height: 45vh; 
    background-color: #fdf0d5;
    display: flex;
    justify-content: space-around;
    color: #000;
    align-items: center;
    /* padding: 6vw; */
    font-family: Georgia;
    align-items: center;
    flex-direction: column;
    margin: 0;  /* Reset default margins */
    flex-direction: column;
    /* gap: -30px;*/
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height for centralization */
  text-align: center;
  margin-bottom: 0px;
}
.logo img {
  max-width: 100%;
  height: auto;
}
.cont-logo-insider {
    display: flex;
    flex-direction: column;
    gap: -30px;
    justify-content: center;
    align-items: center;
}
.cont-logo-img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.logo .logo-img {
    width: 5rem;
}
.logo .logo-text {
    width: 5rem;
}
.logo p {
    color: #000; 
    font-family: georgia;  
    text-align: center; 
    font-size: 0.6rem;
}
.list{
    margin-top: 3vw;
    padding: 10px;
    border-radius: 5px;
}
.address {
    padding: 10px;
    border-radius: 5px;
}
.list ul{
    list-style: none;
    padding: 0;
}
.list li{
    margin: 10px 0;
}
.red {
    color: blue;
    margin-bottom: 10px;
    font-size: 1vw;
    font-family: georgia;
    font-weight: bolder;
}
.blue {
    color: red;
    margin-bottom: 10px;
    font-size: 1vw;
    font-family: georgia;
    font-weight: bolder;
}
.green {
    color: black;
    margin-bottom: 10px;
    font-size: 1vw;
    font-family: georgia;
    font-weight: bolder;

}
.address {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 3vw;
    margin-bottom: 20px;
}
.contact-details {
  margin-bottom: 20px;
}
.contact-details h4 {
    color: #000;
    font-size: 1vw;
    font-family: georgia;
    font-weight: bolder;
    margin: 0;
}
.contact-details p {
    color: #000;
    font-size: 0.8vw;
    margin: 0;
}


@media (max-width: 768px) {
  .list {
    display: none; /* Hide the lists on smaller screens */
  }
  
  .container {
    grid-template-columns: 1fr; /* Only one column for contact details */
    gap: 15px;
  }
  .main-contact {
    padding: 15px;
  }
}




@media  (max-width: 650px) {
    .logo{
        width: 15vw;
        margin-top: 5vw;
        text-align: center;

    }
    .logo p{
        width: 37vw;
        font-size: 2vw;
        margin-left: -10vw;
        text-align: center;
    }

    .logo .logo-img {
        width: 15vw;
    }
    .logo .logo-text {
        width: 17vw;
    }
    .contact-details h4{
        font-size: 2.5vw;
    }
    .contact-details p{
        font-size: 2vw;
    }
    .list ul li{
        font-size: 1.2vw;
    }
    .list{
        display: none;
        margin-top: 3vw;
    }
    .address{
        margin-top: 9vw;
    }
    .cont-logo-insider {
        margin: auto;
    }
}
@media  (max-width: 450px) {
    .logo{
        margin-top: 8vw;
    }
    .contact {
        height: 30vh;
    }
    .logo p{
        font-size: 2.4vw;
    }
    .logo .logo-img {
        width: 20vw;
    }
    .logo .logo-text {
        width: 22vw;
    }
    .list{
        margin-top: 9vw;
    }
    .address {
        margin-top: 7vh;
    }
    /* .contact-details h4{
        
    }
    .contact-details p {

    } */
    .cont-logo-insider {

    }
}