html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

.landing-page {
    background: #1f1515;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    transform: translateZ(0);
    overflow: hidden;
}

.landing-page > * {
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.main-contact {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    flex-grow: 1; 
}

/* Media Queries */
@media (max-width: 650px) {
    .landing-page {
        padding: 10px;
    }
    .landing-page > * {
        margin-bottom: 15px;
    }
    .main-contact {
        padding: 15px;
    }
}

@media (max-width: 450px) {
    .landing-page {
        padding: 5px;
    }
    .landing-page > * {
        margin-bottom: 10px;
    }
    .main-contact {
        padding: 10px;
    }
    h2 {
        font-size: 1.2rem;
    }
    p {
        font-size: 0.9rem;
    }
}
