.booking {
  background: url(../../../assets/register-img.jpg); /* Replace with your image path */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Optional: Center the image */
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.book-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
/* Ensure spinner rotates */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Truncate file name */
.truncate {
  max-width: 200px; /* Adjust as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;

}
.book-hue {
  background-color: rgba(31, 21, 21, 0.9);
  width: inherit;
  height: inherit;
}
.book-nav {
  width: inherit;
  height: 10vh;
  border-bottom: thin solid #fdf0d5;
  display: flex;
}
.book-logo-holder {
  width: 30%;
  display: flex;
}
.book-logo-holder img {
  width: 12vw;
  margin-left: 70px;
}
.book-nav-list {
  width: 70%;
  display: flex;
}
.book-nav-list ul {
  display: flex;
  list-style: none;
  margin-left: 50px;
}
.book-nav-list ul li {
  display: inline-block;
  margin: 3.5vh 2vw;
  font-size: 1.4vw;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.book-body {
  width: inherit;
  height: 80vh;
}
.book-form {
  margin: auto;
  width: 60%;
  height: 50vh;
  /* display: flex;
    flex-direction: column;
    align-items: cen; */
}
.book-form h1 {
  display: block;
  margin: auto;
  margin: 4vh 1vw;
  font-size: 4vw;
  font-weight: normal;
  text-align: center;
}
.book-form p {
  margin: auto;
  text-align: center;
}
.book-form input,
.book-form button,
.book-form div {
  display: block;
  margin: auto;
  width: 90%;
  height: 6.7vh;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: thin solid #fdf0d5;
  margin: 4vh 2vw;
  font-size: 1.3vw;
}
.book-form input::placeholder {
  color: #fdf0d5;
}

.book-file-label {
  border: thin solid rgba(253, 240, 213, 1);
  /* padding: 1vh 2vw; */
  width: 9vw;
  height: 5vh;
  font-size: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: right;
}
.book-form div span {
  font-size: 1.3vw;
}
.book-form div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.book-submit {
  background-color: #fdf0d5 !important;
  color: #1f1515;
  border-radius: 10px;
}
.dbr-mob-menu {
  display: none;
}

@media (max-width: 650px) {
  .book-form input::placeholder {
    font-size: 2.3vw;
  }
  .book-form input {
    font-size: 2.3vw;
  }
  .book-form {
    width: 80%;
  }
  .book-submit {
    font-size: 2.3vw;
  }
  .book-logo-holder img {
    width: 20vw;
  }
  .book-nav-list ul {
    display: none;
  }
  .dbr-mob-menu {
    display: block;
    width: 7vw;
    height: 5vh;
    margin-top: 3vh;
    margin-right: 7vw;
  }
  .book-form button {
    font-size: 3.5vw;
  }
  .book-form h1 {
    text-align: center;
    font-size: 9vw;
  }
  .book-form div span {
    font-size: 2.3vw;
  }
  .book-file-label {
    width: 20vw;
    /* height: 4vh; */
    font-size: 2.3vw;
  }
}

@media (max-width: 450px) {
  .book-form {
    width: 90%;
  }
  .book-form input::placeholder {
    font-size: 4.3vw;
  }
  .book-form input {
    font-size: 4.3vw;
  }
  .book-submit {
    font-size: 10.3vw;
  }
  .book-logo-holder img {
    width: 25vw;
  }
  .book-form h1 {
    font-size: 8vw;
  }
  /* .book-form button {
        font-size: 4vw;
    } */
  .book-form div span {
    font-size: 4.3vw;
  }
  .book-file-label {
    width: 30vw;

    font-size: 4.3vw;
  }
}
