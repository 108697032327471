.dashboardLaundry {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: rgba(253, 240, 213, 1);
}
.dashLaundry-guest-section {
    width: 25%;
    height: inherit;
}
.dashLaundry-content-section {
    width: 75%;
    height: inherit;
    color: rgba(31, 21, 21, 1);

}
.dashLaundry-content-head {
    width: 100%;
    height: 10vh;
    border-bottom: 0.2vh solid #1F1515;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dashLaundry-content-head a {
    color: rgba(31, 21, 21, 1);
    margin-right: 7vh;
    font-size: 1.05vw;
}
.dashLaundry-content-body {
    width: 100%;
    height: 90vh;
}
.dashLaundry-content-body p {
    color: rgba(31, 21, 21, 1);
    font-size: 2vw;
    height: 8vh;
    display: flex;
    align-items: center; 
    margin-left: 5vw;
}
.dashLaundry-content-table-section {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center; 
    justify-content: center;
    
}
.dashLaundry-web-table-holder {
    width: 90%;
    height: 70vh;
    background-color: rgba(255, 250, 238, 1);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) ;
}
.dashLaundry-web-table {
    width: 95%;
    height: auto;
    margin: auto;
    /* border: thin solid #000; */
}
.dashLaundry-web-table tr {
    border: thin solid #000;
    height: 7vh;

}
.dashLaundry-web-table thead tr th{
    color: #1F1515;
    border-bottom: 0.2vh solid #000;
    font-size: 1.05vw;
}
.dashLaundry-web-table tbody tr td  {
    color: #1F1515;
    text-align: center;
    border-bottom: 0.2vh solid #000;
    font-size: 1.05vw;
    
}
.dashLaundry-web-table tr td div {
    color: #1F1515;
    /* text-align: center; */
}