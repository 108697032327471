.availableRooms {
  display: inline-block;
}
.availableRooms-dashboard-button {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 border: none;
 background-color: #1F1515;
 border-radius: 0.6vw;
 border-collapse: collapse;
 margin: 1vh 2vw;
 padding: 0.5vh 3vw 0.5vh 1vw ;
 cursor: pointer;
}
.availableRooms-dashboard-button div {
  cursor: pointer;
  font-size: 2vw;
}
.availableRooms-dashboard-button span {
  cursor: pointer;
  font-size: 1vw;
}
@media (max-width: 650px) {
  .availableRooms-dashboard-button {
    /* padding: 1vh 7vw 0.5vh 0.5vw; */
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.5vw;
    border-radius: 1vw;
  }
  .availableRooms-dashboard-button div {
    font-size: 3vw;
    color: #fdf0d5;
  } 
  .availableRooms-dashboard-button span {
    font-size: 2.5vw;
    color: #fdf0d5;
    font-weight: normal;
  }
}