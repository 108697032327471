.navBar {
    max-width: 100%;
    height: 70vh;
    background: #1f1515;
    display: flex;    
    justify-content: space-between;
    flex-direction: row;
}
    /* hidden */
    /* opacity: 0;
    filter: blur(1em);
    transform: translateX(-100%);
    transition: all 1s;
} */
/* .show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
} */

.navbar-col {
    background: #1f1515;
    width:  43.3%;
    height: inherit;
    display: flex;
    flex-direction: column;

}
.nav-img-container {
    width: 56.7%;
    height: inherit;
    background: url('../../assets/Ropa1.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.mob-logo, .mob-menu {
    display: none;
}


.navBar ul {
    max-width: 100%;
    display: flex;
    padding: 1vh 2.2vw 0;
        /* justify-content: center;
        margin-bottom: 200px;  */
}

.navBar ul li {
    padding: 1.7vw 1.7vw;
    list-style: none;
    font-size: 1.3vw;
    font-family: Georgia;
    cursor: pointer;

}
.logo-container {
    /* */
    max-width: 100%;
    width: 90%;
    height: 40%;
    text-align: center;
    margin:  auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    
}
.logo-arena {

}

.logo-container .navlogo {

    width: 25%;
    margin-top: 15vh;
    margin: auto;
    display: block;
    align-items: center;
    animation: floating 3s ease-in-out infinite;
}
@keyframes floating {
    0% { transform: translateY(0); }
    50% { transform: translateY(-0.625rem); }
    100% { transform: translateY(0); }
}
.logo-container .two {
    padding-bottom: 50px;
    width: 30%;
    margin: auto;
    display: block;
}

.logo-container button{
    margin: auto;
    padding: 0.7vw;
    color: #fdf0d5;
    font-family: Georgia;
    font-size: 1.7vw;
    background-color: #1f1515;
    border-radius: 5px;
    border: thin solid #fdf0d5;
    display: block;
}
.logo-container button:hover {
    background-color: #fdf0d5;
    color: #1f1515;
    transition: all 0.5s;
}

.nav-contact {
    text-align: left;
    height: 7vh;
    width: 20vw;
    position: absolute;
    bottom: 1vh;  /*Adjust as needed  */
    left: 2vw;
    cursor: pointer;
}
.nav-contact p {
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #1f1515;
    line-height: 1.3;
    font-size: 1.2vw;
    z-index: 100;
} 

@media (max-width: 650px) {
    .navBar {
        flex-direction: column;
        height: 95vh;
        
    }
    .navbar-col {
        width: 100%;
        height: 15vh;
        display: flex;
        padding: 15px 0;
        justify-content: space-between;
    }
    .nav-img-container {

    }
    
    .nav-img-container {
        width: 100%;
        height: 80vh;
        position: relative;
    } 
    .logo-container {
        display: none;
    }
    .navBar ul {
        /* position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background: #1f1515;
        opacity: 0.95;
        z-index: 1;
        width: 100%;
        height: 100vh;
        padding-top: 70px;
        transition: .05s;
        flex-direction: column; */
        display: none;
    }
    .navBar ul li {
        font-size: 2vw;
        margin: 10px 15px;
        display: block;
        cursor: pointer;
    }
    .logo-container button {
        display: none;
    }
    
    .mob-logo, .mob-menu {
        display: block;
        align-items: center;
        cursor: pointer;
    }
    .mob-logo {
        width: 40vw;
        height: fit-content;
        margin-left: 6vw;
        margin-top: 3.7vh;
    }
    .mob-menu {
        /* float: right; */
        position: absolute;
        top: 6.7vh; 
        right: 10vw; 
        width: 8.8vw;
    }
    .nav-contact {
        text-align: right;
        height: 10vh;
        width: 30vw;
        position: absolute;
        /* bottom: 10px; */
        left: 65vw;
    }
        .nav-contact p {
        font-size: 2.3vw;
    }
}

@media (max-width: 450px) {
    .navBar {
        height: 62vh;
    }
    .navbar-col {
        height: 12vh;
        padding: 0px;
    }
    .mob-menu {
        top: 5vh;
    }
    .nav-img-container {
        height: 50vh;
    }
    .nav-contact {
        text-align: right;
        height: 4vh;
        width: 30vw;
        position: absolute;
        /* bottom: 10px; */
        left: 62vw;
    }
        .nav-contact p {
        font-size: 2.3vw;
        /* font-weight: bold; */
    }
}