.CashierManageGuest {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.CashierManageGuest-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.CashierManageGuest-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
}
.CashierManageGuest-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.CashierManageGuest-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.CashierManageGuest-space-body {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.CashierManageGuest-title-board {
  width: 85%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CashierManageGuest-title-board p {
  color: #1f1515;
  font-size: 2vw;
}
.CashierManageGuest-title-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.CashierManageGuest-title-board input {
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 0.2vh solid #1f1515;
  color: #1f1515;
  font-size: 1.3vw;
}

.CashierManageGuest-board {
  width: 85%;
  height: 70vh;
  background-color: rgb(255, 246, 238);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.CashierManageGuest-center-board {
  width: 90%;
  height: 65vh;
}
.CashierManageGuest-center-board p {
  color: #1f1515;
  font-size: 1.2vw;
}
.CashierManageGuest-table-head {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.CashierManageGuest-table-head p {
  width: 15%;
  font-weight: bold;
}
.CashierManageGuest-table-body {
  width: 100%;
  height: 87%;
  overflow-y: scroll;
}
.CashierManageGuest-table-body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.CashierManageGuest-table-foot {
  width: 100%;
  height: 17vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 0.2vh solid #1f1515;
}
.CashierManageGuest-table-foot-center {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CashierManageGuest-table-foot-center h2 {
  color: #1f1515;
  font-size: 1.7vw;
}
.CashierManageGuest-table-row {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2vh solid #1f1515;
}
.CashierManageGuest-table-row p {
  width: 15%;
}
.CashierManageGuest-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%;
}
.CashierManageGuest-id p {
    width: 50%;
}
.CashierManageGuest-id span {
    margin-right: 25%;
}
.CashierManageGuest-id span img {
    width: 1.5vw;
}
.CashierManageGuest-mobile-header {
  display: none;
}


@media (max-width: 650px) {
  .CashierManageGuest-mobile-header {
    display: block;
  }
  .CashierManageGuest-space {
    background-color: #FDF0D5;
  }
  .CashierManageGuest-profile {
    display: none;
  }
  .CashierManageGuest-space  {
    width: 100%;
  }
  .CashierManageGuest-space-head {
    display: none;
  }
  .CashierManageGuest-table-head {
    display: none;
  }
  .CashierManageGuest-board {
    width: 100%;
    box-shadow: none;
    background-color: #FDF0D5;
    height: auto;

  }
  .CashierManageGuest-table-body {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    height: 70vh;
  }
  .CashierManageGuest-title-board {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    gap: 2vh;
    padding: 1vh 0;
  }
  .CashierManageGuest-title-board h2 {
    font-size: 5vw;
    font-weight: normal;
  }
  .CashierManageGuest-title-board input {
    font-size: 4vw;
  }
  .CashierManageGuest-center-board {
    height: auto;
  }
  .CashierManageGuest-title-board p {
    font-size: 4vw;
  }
}