.ReceptionHeader-header {
    width: 100%;
    height: 10vh;
    background-color: rgba(31, 21, 21, 0.9);
}
.ReceptionHeader-nav {
    width: inherit;
    height: 10vh;
    border-bottom: thin solid #fdf0d5;
    display: flex;
    align-items: center;
}
.ReceptionHeader-logo-holder {
    width: 30%;
    display: flex;
}
.ReceptionHeader-logo-holder img {
    width: 12vw;
    margin-left: 10px;
}
.ReceptionHeader-nav-list {
    width: 70%;
    display: flex;
}
.ReceptionHeader-nav-list ul {
    display: flex;
    list-style: none;
    margin-left: 2vw
}
.ReceptionHeader-nav-list ul li {
    display: inline-block;
    margin: 3.5vh 2vw;
    font-size: 1.4vw;
    cursor: pointer;
}

.ReceptionHeader-mob-menu {
    display: none;
}

@media (max-width: 650px) {
    .ReceptionHeader-nav {
        background-color: #1F1515;
    }
    .ReceptionHeader-logo-holder img {
        width: 30vw;
        margin-left: 7vh;
    }
    .ReceptionHeader-nav-list ul {
        display: none;
    }
    .ReceptionHeader-mob-menu {
        display: block;
        width: 7vw;
        height: auto;
        margin-top: 1.7vh;
        margin-right: 7vw;
    }
}