.located {
    width: 100%;
    height: 100vh;
    background-color: #fdf0d5;
    display: flex;
    flex-direction: row;
}

.loc-text-sec {
    width: 50.7%;
    z-index: 10;
    display: flex;
    justify-content: center;
}
.loc-text-holder {
    margin: auto 0;
    height: 47vh;
    width: 42vw;
    margin-left: 13vw;
    display: flex;
    flex-direction: column;
}
.loc-text-sec p, .loc-text-sec h2 {
    color: #000;
    font-family: Georgia;
    text-align: right;
    right: 0;
    font-weight: normal;
    word-wrap: break-word;
}
.loc-text-sec h2 {
    width: 44vw;
    font-size: 5.8vw;
}
.loc-text-sec p {
    width: 35.7vw;
    height: 7.6vh;
    font-size: 1.2vw;
    margin-left: 8.5vw;
}
.loc-img-sec {
    width: 49.3%;
    display: flex;
}
.loc-img {
    width: 33.5vw;
    height: 70vh;
    float: left;
    margin: auto 0;
    background: url(../../assets/Ropa8.jpeg);
    background-repeat: no-repeat;
    background-size: contain;
}



@media screen and (min-width: 1000px) or (not (orientation: landscape)) {
    .located {
        height: 80vh;
    }
    .loc-img {
        height: 56vh;
    }
    .loc-text-holder {
        height: 37vh;
    }
}
@media (max-width: 650px) {
    .located {
        height: 60vh;
    }
    .loc-img {
        height: 42vh;
    }
    .loc-text-holder {
        height: 27vh; 
    }
    .loc-text-sec p {
        width: 40.7vw;
        height: 7.6vh;
        font-size: 1.9vw;
        margin-left: 3vw;
    }
}

@media (max-width: 450px) {
    .located {
        height: 40vh;
    }
    .loc-img {
        height: 35vh;
    }
    .loc-text-holder {
        height: 23vh;
    }
    .loc-text-sec, .loc-img-sec {
        height: 30vh;
        margin-top: 7vh;
    }
} 