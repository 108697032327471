.CreateStaff {
  background: url(../../../assets/register-img.jpg); /* Replace with your image path */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Optional: Center the image */
  width: 100%;
  height: 100vh;
}

.CreateStaff-hue {
  background-color: rgba(31, 21, 21, 0.9);
  width: inherit;
  height: inherit;
}

/* Ensure spinner rotates */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
/* Truncate file name */
.truncate {
  max-width: 200px; /* Adjust as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.CreateStaff-body {
  width: inherit;
  height: 80vh;
}
.CreateStaff-form {
  margin: auto;
  width: 60%;
  /* height: 50vh; */
}
.CreateStaff-form h1 {
  display: block;
  margin: auto;
  margin: 2vh 1vw;
  font-size: 3vw;
  font-weight: normal;
  text-align: center;
}
.CreateStaff-form label,
.CreateStaff-form button,
.CreateStaff-form div,
.CreateStaff-form section {
  display: block;
  margin: auto;
  width: 90%;
  height: 5vh;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: thin solid #fdf0d5;
  margin: 2.5vh 1vw;
  font-size: 1.3vw;
}

.CreateStaff-form section {
  border-bottom: none;
}
.CreateStaff-form section label {
  /* border-width: 100%; */
  margin: 0;
  width: 48%;
}
.CreateStaff-form section label select {
  background: transparent;
  outline: none;
  border: none;
  font-size: 1.3vw;
}
.CreateStaff-form section label select option {
  background-color: #1f1515;
  color: #fdf0d5;
  font-size: 1.3vw;
}
.CreateStaff-form label {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.CreateStaff-form .seprate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CreateStaff-form input::placeholder {
  color: #fdf0d5;
}

.CreateStaff-form label input {
  background: transparent;
  border: none;
  outline: none;
  width: 80%;
  padding: 0 1vw;
  font-size: 1.3vw;
}
.CreateStaff-form label span {
  margin-bottom: 1vh;
}
input[type="file"] {
  display: none;
}

.CreateStaff-file-label {
  border: thin solid rgba(253, 240, 213, 1);
  /* padding: 1vh 2vw; */
  width: 9vw;
  height: 5vh;
  font-size: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: right;
}
.CreateStaff-form div span {
  font-size: 1.3vw;
}
.CreateStaff-form div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CreateStaff-submit {
  background-color: #fdf0d5 !important;
  color: #1f1515;
  border-radius: 0.5vw;
}

@media (max-width: 650px) {
  .CreateStaff-form input::placeholder {
    font-size: 2.3vw;
  }
  .CreateStaff-form {
    width: 80%;
  }
  .CreateStaff-submit {
    font-size: 2.3vw;
  }
  /* */
  .CreateStaff-form button {
    font-size: 3.5vw;
  }
  .CreateStaff-form h1 {
    text-align: center;
    font-size: 9vw;
  }
  .CreateStaff-form div span {
    font-size: 2.3vw;
  }
  .CreateStaff-file-label {
    width: 20vw;
    /* height: 4vh; */
    font-size: 2.3vw;
  }
  .CreateStaff-form label {
    font-size: 2.5vw;
  }
  .CreateStaff-form label input {
    font-size: 2.5vw;
  }
  .CreateStaff-form section label select {
    font-size: 2.5vw;
  }
  .CreateStaff-form section label select option {
    font-size: 2.5vw;
  }
}

@media (max-width: 450px) {
  .CreateStaff-form {
    width: 90%;
  }
  .CreateStaff-form input::placeholder {
    font-size: 4.3vw;
  }
  .CreateStaff-submit {
    font-size: 10.3vw;
  }
  .CreateStaff-logo-holder img {
    width: 25vw;
  }
  .CreateStaff-form h1 {
    font-size: 8vw;
  }
  .CreateStaff-form div span {
    font-size: 4.3vw;
  }
  .CreateStaff-file-label {
    width: 30vw;

    font-size: 4.3vw;
  }
  .CreateStaff-form label {
    font-size: 4.5vw;
  }
  .CreateStaff-form label input {
    font-size: 4.5vw;
  }

  .CreateStaff-form section label {
    width: 45%;
  }
  .CreateStaff-form section label select {
    font-size: 2.5vw;
  }
  .CreateStaff-form section label select option {
    font-size: 4.5vw;
  }

}
