.ReceptionAccount {
  width: 100%;
  height: 100vh;
  background-color: rgba(253, 240, 213, 1);
}
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
}
.spinner-icon {
  animation: spin 1s linear infinite;
  color: #007bff; /* Change this to your desired color */
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.error {
  color: red;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
}
.ReceptionAccount-web {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(253, 240, 213, 1);
}
.ReceptionAccount-guest-section {
  width: 25%;
  height: inherit;
}
.ReceptionAccount-content-section {
  width: 75%;
  height: inherit;
  color: rgba(31, 21, 21, 1);
}
.ReceptionAccount-content-head {
  width: 100%;
  height: 10vh;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ReceptionAccount-content-head a {
  color: rgba(31, 21, 21, 1);
  margin-right: 7vh;
  font-size: 1.05vw;
}
.ReceptionAccount-content-body {
  width: 100%;
  height: 90vh;
}
.ReceptionAccount-content-body p {
  color: rgba(31, 21, 21, 1);
  font-size: 2vw;
  height: 8vh;
  display: flex;
  align-items: center;
  margin-left: 5vw;
}
.ReceptionAccount-content-table-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReceptionAccount-bar-holder {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}
.ReceptionAccount-bar-holder div p {
  color: #1f1515;
  margin-left: 5vw;
  font-size: 2vw;
}
.ReceptionAccount-bar-holder div select {
  background: #D9D9D9;
  color: #1f1515;
  border: none;
  font-size: 1.3vw;
  outline: none;
  margin: 0 1vw 0 1vw;
  border-radius: 1vw;
  padding: 0.5vh 0vw 0.5vh 1vw;
}
.ReceptionAccount-bar-holder div select option {
  background: transparent;
  color: #1f1515;
  font-size: 1.3vw;
  outline: none;
  margin: 0 1vw 0 1vw;
  border-radius: 1vw;
  padding: 0.5vh 0vw 0.5vh 1vw;
}

.ReceptionAccount-web-table-holder {
  width: 90%;
  height: 70vh;
  background-color: rgba(255, 250, 238, 1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
}
.ReceptionAccount-web-table {
  width: 95%;
  height: 68vh;
  margin: auto;
}
.ReceptionAccount-web-table .header_div {
  border-bottom: thin solid #000 !important;
  height: 7vh;
}
.ReceptionAccount-web-table thead div {
  color: #1f1515;

  font-size: 1.05vw;
  display: flex;
  justify-content: space-around;
}
.ReceptionAccount-web-table thead div span {
  color: #1f1515;
  text-align: center;
  font-weight: 600;
  font-size: 1.05vw;
  width: 17%;
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-bottom: 1vh;
}
.ReceptionAccount-web-table section {
  overflow-y: scroll;
  height: 60vh !important;
}
.mobile-dasboardAccount {
  display: none;
}
.ReceptionAccount-mobile-header {
  display: none;
}

/* web section ends here */

/* Mobile Styling here */

@media (max-width: 650px) {
  .ReceptionAccount-guest-section {
    display: none;

  }
  .ReceptionAccount-bar-holder div select option {
    font-size: 3vw;
  }
  .ReceptionAccount-mobile-header {
    display: block;
  }
  .ReceptionAccount-web {
    height: auto;
  }
  .ReceptionAccount {
    height: auto;
  }

  .ReceptionAccount-web-table {
    height: auto;
  }

  .ReceptionAccount-web-table thead {
    display: none;

  }
  .ReceptionAccount-content-body {
    height: auto;
  }
  .ReceptionAccount-content-table-section {
    height: auto;
  }
  .ReceptionAccount-content-section {
    width: 100%;
    height: auto;
  }
  .ReceptionAccount-web-table section {
    width: 100%;
    height: auto;
    overflow: visible;
    background-color: rgba(253, 240, 213, 1);
  }
  .ReceptionAccount-web-table-holder {
    width: 100%;
    height: auto;
    box-shadow: none;
    background-color: transparent;
  }
  .ReceptionAccount-content-head {
    border-bottom: none;
    height: 5vh;
  }
  .ReceptionAccount-content-head a {
    font-size: 2.5vw;
    margin-right: 8vw;
  }
  .ReceptionAccount-bar-holder {
    margin: auto;
  }
  .ReceptionAccount-bar-holder div p {
    font-size: 5vw;
  }
  .ReceptionAccount-bar-holder div select {
    font-size: 3vw;
  }
}

/* @media (max-width: 650px) {
  .mobile-dasboardAccount {
    display: block;
  }
  .head-container {
    width: 90%;
    padding-left: 4.5vw;
    padding-top: 5vh;
  }
  h4 {
    color: #1f1515;
    font-size: 20px;
    font-weight: 100;
  }
  .ReceptionAccount-web {
    display: none;
  }

 

  .dropdown-mobile-table {
    font-weight: 100;
    display: block;
    width: 90%;
    height: 75vh;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    margin: 2vw 3vw 4vw 4vw;
    overflow-y: scroll;
  }

  .dropdown {
    height: auto;
  }
  .dropdown-btn {
    background-color: #fffaee;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding: 3px;
  }
  .dropdown-item {
    background-color: #ececec;
    width: 100%;
    height: auto;
    display: flex;
    padding: 3px;
  }
  .dropdown-item-head {
    color: #1f1515;
    text-align: left;
    display: flex;
  }
  .dropdown-item-left {
    
    margin-left: 4vw;
    height: auto;
    padding: 3px;
  }
  .dropdown-item-left p {
    color: #1f1515;
    font-weight: 100;
  }
  .dropdown-item-right {
    
    height: auto;
    flex-direction: column;
    padding: 3px;
  }
} */
