.CashierGuestDashboardRooms {
  width: 100%;
  height: 100vh;
  background-color: #fdf0d5;
  display: flex;
}
.CashierGuestDashboardRooms-profile {
  width: 25%;
  height: inherit;
  background-color: #1f1515;
}
.CashierGuestDashboardRooms-space {
  width: 75%;
  height: 100vh;
  background-color: #fdf0d5;
}
.CashierGuestDashboardRooms-space-head {
  height: 10vh;
  width: 100%;
  border-bottom: 0.2vh solid #1f1515;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.CashierGuestDashboardRooms-space-head p {
  color: #1f1515;
  margin-right: 5vw;
  font-size: 1vw;
}
.CashierGuestDashboardRooms-space-body {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.CashierGuestDashboardRooms-title-board {
  width: 85%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CashierGuestDashboardRooms-user-detail {
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CashierGuestDashboardRooms-title-board p {
  color: #1f1515;
  font-size: 2vw;
}
.CashierGuestDashboardRooms-button-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}
.CashierGuestDashboardRooms-button-select button {
  background-color: #1f1515;
  color: #fdf0d5;
  padding: 1vh 1vw;
  border: none;
  margin: 0 0.5vw 0 0.5vw;
  border-radius: 2vw;
  outline: none;
}

.CashierGuestDashboardRooms-board {
  width: 85%;
  height: 70vh;
  background-color: #ececec;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: s; */
}
.CashierGuestDashboardRooms-dropdown-holder {
  width: 100%;
  height: 45vh;
  overflow-y: scroll;
}

.CashierGuestDashboardRooms-drop-down {
  width: 100%;
}
.CashierGuestDashboardRooms-drop-down-btn {
  width: 100%;
  height: 5vh;
  background-color: #fdf0d5;
}
.CashierGuestDashboardRooms-left-head-data {
  width: 17%;
  height: 5vh;
  background-color: #fdf0d5;
  margin-left: 3%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12%;
}
.CashierGuestDashboardRooms-left-head-data img {
  width: 1vw;
}
.CashierGuestDashboardRooms-left-head-data span {
  color: #1f1515;
  font: 1.2vw;
}
.CashierGuestDashboardRooms-drop-down-body {
  width: 100;
}
.CashierGuestDashboardRooms-table {
  width: 100%;
}
.CashierGuestDashboardRooms-table-head {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.CashierGuestDashboardRooms-table-head p {
  width: 15%;
  color: #1f1515;
  font-size: 1.2vw;
}
.CashierGuestDashboardRooms-table-body {
  width: 100%;
}
.CashierGuestDashboardRooms-table-row {
  width: 100%;
  height: 5vh;
  background-color: #ececec;
  border: 1px solid #402f2f17;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.CashierGuestDashboardRooms-table-row p {
  color: #1f1515;
  width: 15%;
  font-size: 1.2vw;
}
.CashierGuestDashboardRooms-foot {
  width: 100%;
  height: 20vh;
  display: flex;
  /* align-items: baseline; */
  flex-direction: column;
  justify-content: flex-end;
}
.CashierGuestDashboardRooms-foot-wrapper {
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.CashierGuestDashboardRooms-foot-left {
  /* width: ; */
  margin-left: 5%;
}
.CashierGuestDashboardRooms-foot-left h2 {
  color: #000000;
}

.CashierGuestDashboardRooms-foot-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}
.CashierGuestDashboardRooms-foot-right p {
  color: #000000;
  font-size: 1.2vw;
  font-weight: bolder;
}
.CashierGuestDashboardRooms-foot-right .CashierGuestDashboardRooms-red {
  color: red;
}
.CashierGuestDashboardRooms-foot-right .CashierGuestDashboardRooms-green {
  color: green;
}
.clicked-class {
  transform: rotate(90deg);  
  transition: transform 0.3s ease-in-out;
}

.clicked-back {
  transform: rotate(0deg);  
  transition: transform 0.3s ease-in-out;
}

.CashierGuestDashboardRooms-mobile-header {
  display: none;
}

@media (max-width: 650px) {
  .CashierGuestDashboardRooms-mobile-header {
    display: block;
  }
  .CashierGuestDashboardRooms-space-head {
    display: none;
  }
  .CashierGuestDashboardRooms-profile {
    display: none;
  }
  .CashierGuestDashboardRooms-space {
    width: 100%;
    height: auto;
  }
  .CashierGuestDashboardRooms-space-body {
    height: auto;
  }
  .CashierGuestDashboardRooms-title-board {
    flex-direction: column-reverse;
    gap: 2vh;
    margin: 2vh 0;
  }
  .CashierGuestDashboardRooms-user-detail {
    /* justify-content: flex-start; */
    align-self: flex-start;
    width: auto;
  }
  .CashierGuestDashboardRooms-button-select {
    width: 100%;
    justify-content: space-around;
  }
  .CashierGuestDashboardRooms-button-select button {
    font-size: 3vw;
    padding: 1vh 4vw;
  }
  .CashierGuestDashboardRooms-title-board p {
    font-size: 3vw;
  }
  .CashierGuestDashboardRooms-foot-wrapper, .CashierGuestDashboardRooms-foot-right  {
    flex-direction: column;
  }
  .CashierGuestDashboardRooms-foot-wrapper {
    height: 25vh;
    align-items: flex-start;
    /* gap: 0vh; */
  }
  .CashierGuestDashboardRooms-board {
    height: 75vh;
    justify-content: space-between;
  }
  .CashierGuestDashboardRooms-foot-right {
    /* justify-content: flex-start; */
    height: auto;
    align-items: flex-start;
    padding: 0 0 3vh 2vw;

  }
  .CashierGuestDashboardRooms-foot-right div {
    display: flex;
    gap: 2vh;
  }
  .CashierGuestDashboardRooms-foot-right p {
    font-size: 3vw;
    padding: 0.5vh 0;
  }
  .CashierGuestDashboardRooms-foot-left h2 {
    font-size: 4vw;
  }
  .CashierGuestDashboardRooms-dropdown-holder {
    height: 50vh;
  }
  .CashierGuestDashboardRooms-left-head-data {
    width: 40%;
  }
  .CashierGuestDashboardRooms-left-head-data img {
    width: 2vw;
  }
  .CashierGuestDashboardRooms-table-head {
    display: none;
  }
}